import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { alignSelf, border, margin, position, layout } from 'styled-system';

const hoverable = ({ withHover }) => {
  if (!withHover) return '';

  return `
    cursor: pointer; 
  `;
};

const propTypes = {
  alt: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
};

export const StyledImg = styled.img`
  ${alignSelf}
  ${margin}
  ${layout}
  ${hoverable}
  ${border}
  ${position}
`;

// eslint-disable-next-line no-shadow
const Img = ({ maxWidth, height, alt, ...rest }) => (
  <StyledImg alt={alt} height={height} maxWidth={maxWidth} {...rest} />
);

Img.propTypes = propTypes;

Img.defaultProps = {
  alt: '',
  height: 'auto',
  maxWidth: '100%',
};

export { Img };
