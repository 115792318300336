import {
  EMPLOYER_CASE_NOTE_ADD_NEW,
  EMPLOYER_CASE_NOTE_ADD_NEW_SUCCESS,
  EMPLOYER_CASE_NOTE_ADD_NEW_ERROR,
  EMPLOYER_CASE_NOTE_DELETE,
  EMPLOYER_CASE_NOTE_DELETE_ERROR,
  EMPLOYER_CASE_NOTE_DELETE_SUCCESS,
  EMPLOYER_CASE_NOTE_EDIT,
  EMPLOYER_CASE_NOTE_EDIT_ERROR,
  EMPLOYER_CASE_NOTE_EDIT_SUCCESS,
  EMPLOYER_CASE_NOTE_GET_BY_ID,
  EMPLOYER_CASE_NOTE_GET_BY_ID_ERROR,
  EMPLOYER_CASE_NOTE_GET_BY_ID_SUCCESS,
  EMPLOYER_CASE_NOTE_ADD_NEW_REQUEST,
  EMPLOYER_CASE_NOTE_QUERY,
  EMPLOYER_CASE_NOTE_QUERY_SUCCESS,
  EMPLOYER_CASE_NOTE_QUERY_ERROR,
} from '../actions';

export const getEmployerCaseNoteQuery = query => ({
  type: EMPLOYER_CASE_NOTE_QUERY,
  payload: query,
});

export const getEmployerCaseNoteQuerySuccess = caseNotes => ({
  type: EMPLOYER_CASE_NOTE_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getEmployerCaseNoteQueryError = error => ({
  type: EMPLOYER_CASE_NOTE_QUERY_ERROR,
  payload: error,
});

export const addNewEmployerCaseNote = caseNote => ({
  type: EMPLOYER_CASE_NOTE_ADD_NEW,
  payload: caseNote,
});

export const addNewEmployerCaseNoteRequest = () => ({
  type: EMPLOYER_CASE_NOTE_ADD_NEW_REQUEST,
});

export const addNewEmployerCaseNoteSuccess = CaseNote => ({
  type: EMPLOYER_CASE_NOTE_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewEmployerCaseNoteError = error => ({
  type: EMPLOYER_CASE_NOTE_ADD_NEW_ERROR,
  payload: error,
});

export const editEmployerCaseNote = CaseNote => ({
  type: EMPLOYER_CASE_NOTE_EDIT,
  payload: CaseNote,
});

export const editEmployerCaseNoteSuccess = caseNote => ({
  type: EMPLOYER_CASE_NOTE_EDIT_SUCCESS,
  payload: caseNote,
});

export const editEmployerCaseNoteError = error => ({
  type: EMPLOYER_CASE_NOTE_EDIT_ERROR,
  payload: error,
});

export const deleteEmployerCaseNote = id => ({
  type: EMPLOYER_CASE_NOTE_DELETE,
  payload: id,
});

export const deleteEmployerCaseNoteSuccess = id => ({
  type: EMPLOYER_CASE_NOTE_DELETE_SUCCESS,
  payload: id,
});

export const deleteEmployerCaseNoteError = error => ({
  type: EMPLOYER_CASE_NOTE_DELETE_ERROR,
  payload: error,
});

export const getEmployerCaseNoteById = id => ({
  type: EMPLOYER_CASE_NOTE_GET_BY_ID,
  payload: id,
});

export const getEmployerCaseNoteByIdSuccess = caseNote => ({
  type: EMPLOYER_CASE_NOTE_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getEmployerCaseNoteByIdError = error => ({
  type: EMPLOYER_CASE_NOTE_GET_BY_ID_ERROR,
  payload: error,
});
