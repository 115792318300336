import styled from 'styled-components';
import { color, flexbox, layout, typography, space, textStyle, border } from 'styled-system';

const truncateText = ({ truncate }) => {
  if (!truncate) return '';

  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
};

export const Text = styled.p`
  ${truncateText}
  ${textStyle}
  ${flexbox}
  ${space}
  ${typography}
  ${color}
  ${layout}
  ${border}
`;
