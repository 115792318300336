import {
  CLIENT_FUND_SUPERVISOR_GET_ALL,
  CLIENT_FUND_SUPERVISOR_GET_ALL_SUCCESS,
  CLIENT_FUND_SUPERVISOR_GET_ALL_ERROR,
  CLIENT_FUND_SUPERVISOR_GET_BY_ID,
  CLIENT_FUND_SUPERVISOR_GET_BY_ID_SUCCESS,
  CLIENT_FUND_SUPERVISOR_GET_BY_ID_ERROR,
  CLIENT_FUND_SUPERVISOR_ADD_NEW,
  CLIENT_FUND_SUPERVISOR_ADD_NEW_SUCCESS,
  CLIENT_FUND_SUPERVISOR_ADD_NEW_ERROR,
  CLIENT_FUND_SUPERVISOR_EDIT,
  CLIENT_FUND_SUPERVISOR_EDIT_SUCCESS,
  CLIENT_FUND_SUPERVISOR_EDIT_ERROR,
  CLIENT_FUND_SUPERVISOR_DELETE,
  CLIENT_FUND_SUPERVISOR_DELETE_SUCCESS,
  CLIENT_FUND_SUPERVISOR_DELETE_ERROR,
  CLIENT_FUND_SUPERVISOR_EMAIL_NOTIFICATION,
} from '../actions';
//ClientFundSupervisor
export const getClientFundSupervisors = () => ({
  type: CLIENT_FUND_SUPERVISOR_GET_ALL,
});

export const getClientFundSupervisorsSuccess = items => ({
  type: CLIENT_FUND_SUPERVISOR_GET_ALL_SUCCESS,
  payload: items,
});

export const getClientFundSupervisorsError = error => ({
  type: CLIENT_FUND_SUPERVISOR_GET_ALL_ERROR,
  payload: error,
});

export const getClientFundSupervisorById = id => ({
  type: CLIENT_FUND_SUPERVISOR_GET_BY_ID,
  payload: id,
});

export const getClientFundSupervisorByIdSuccess = item => ({
  type: CLIENT_FUND_SUPERVISOR_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getClientFundSupervisorByIdError = error => ({
  type: CLIENT_FUND_SUPERVISOR_GET_BY_ID_ERROR,
  payload: error,
});

export const addNewClientFundSupervisor = item => ({
  type: CLIENT_FUND_SUPERVISOR_ADD_NEW,
  payload: item,
});

export const addNewClientFundSupervisorSuccess = item => ({
  type: CLIENT_FUND_SUPERVISOR_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewClientFundSupervisorError = error => ({
  type: CLIENT_FUND_SUPERVISOR_ADD_NEW_ERROR,
  payload: error,
});

export const editClientFundSupervisor = item => ({
  type: CLIENT_FUND_SUPERVISOR_EDIT,
  payload: item,
});

export const editClientFundSupervisorSuccess = item => ({
  type: CLIENT_FUND_SUPERVISOR_EDIT_SUCCESS,
  payload: item,
});

export const editClientFundSupervisorError = error => ({
  type: CLIENT_FUND_SUPERVISOR_EDIT_ERROR,
  payload: error,
});

export const deleteClientFundSupervisor = id => ({
  type: CLIENT_FUND_SUPERVISOR_DELETE,
  payload: id,
});

export const deleteClientFundSupervisorSuccess = item => ({
  type: CLIENT_FUND_SUPERVISOR_DELETE_SUCCESS,
  payload: item,
});

export const deleteClientFundSupervisorError = error => ({
  type: CLIENT_FUND_SUPERVISOR_DELETE_ERROR,
  payload: error,
});

export const onOrOffClientFundSupervisorEmailNotification = payload => ({
  type: CLIENT_FUND_SUPERVISOR_EMAIL_NOTIFICATION,
  payload,
});
