import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY,
  GET_COJG_CONTRACT_DOCUMENTS_BY_ID,
  EDIT_COJG_CONTRACT_DOCUMENTS,
  UPLOAD_COJG_CONTRACT_DOCUMENTS,
  DELETE_COJG_CONTRACT_DOCUMENTS,
} from './types';

import {
  getCOJGContractDocumentByIdSuccess,
  getCOJGContractDocumentByQuerySuccess,
  cojgContractDocumentDocumentError,
} from './actions';

const getCOJGContractDocumentByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/COJGContractDocument/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getCOJGContractDocumentByIdRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/COJGContractDocument/${id}`,
    await authHeader()
  );
};

const editCOJGContractDocumentRequest = async employer => {
  return await axios.put(
    `${Config.apiServerHost}/api/COJGContractDocument`,
    employer,
    await authHeader()
  );
};

const uploadCOJGContractDocumentRequest = async (document, contractId) => {
  return await axios.put(
    `${Config.apiServerHost}/api/COJGContractDocument/uploadDocument/${contractId}`,
    document,
    await authHeader()
  );
};

const deleteCOJGContractDocumentRequest = async id => {
  return await axios.delete(
    `${Config.apiServerHost}/api/COJGContractDocument/${id}`,
    await authHeader()
  );
};

function* getCOJGContractDocumentByQuery({ payload }) {
  try {
    const response = yield call(getCOJGContractDocumentByQueryRequest, payload);
    yield put(getCOJGContractDocumentByQuerySuccess(response.data));
  } catch (error) {
    yield put(cojgContractDocumentDocumentError(error));
  }
}

function* getCOJGContractDocumentById({ payload }) {
  try {
    const response = yield call(getCOJGContractDocumentByIdRequest, payload);
    yield put(getCOJGContractDocumentByIdSuccess(response.data));
  } catch (error) {
    yield put(cojgContractDocumentDocumentError(error));
  }
}

function* editCOJGContractDocument({ payload }) {
  try {
    const { contractId, data } = payload;
    const query = {
      contractId,
    };
    yield call(editCOJGContractDocumentRequest, data);
    const response = yield call(getCOJGContractDocumentByQueryRequest, query);
    yield put(getCOJGContractDocumentByQuerySuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(cojgContractDocumentDocumentError(error));
  }
}

function* uploadCOJGContractDocument({ payload }) {
  try {
    const { document, contractId } = payload;
    const query = {
      contractId,
    };
    yield call(uploadCOJGContractDocumentRequest, document, contractId);
    const response = yield call(getCOJGContractDocumentByQueryRequest, query);
    yield put(getCOJGContractDocumentByQuerySuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(cojgContractDocumentDocumentError(error));
  }
}

function* deleteCOJGContractDocument({ payload }) {
  try {
    const { contractId, data } = payload;
    const query = {
      contractId,
    };

    yield call(deleteCOJGContractDocumentRequest, data);
    const response = yield call(getCOJGContractDocumentByQueryRequest, query);
    yield put(getCOJGContractDocumentByQuerySuccess(response.data));
  } catch (error) {
    yield put(cojgContractDocumentDocumentError(error));
  }
}

export function* watchCOJGContractDocumentByQuery() {
  yield takeEvery(GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY, getCOJGContractDocumentByQuery);
}

export function* watchCOJGContractDocumentById() {
  yield takeEvery(GET_COJG_CONTRACT_DOCUMENTS_BY_ID, getCOJGContractDocumentById);
}

export function* wathcEditEmpoyerdocument() {
  yield takeEvery(EDIT_COJG_CONTRACT_DOCUMENTS, editCOJGContractDocument);
}

export function* wathcUploadEmpoyerdocument() {
  yield takeEvery(UPLOAD_COJG_CONTRACT_DOCUMENTS, uploadCOJGContractDocument);
}

export function* watchDeleteCOJGContractDocument() {
  yield takeEvery(DELETE_COJG_CONTRACT_DOCUMENTS, deleteCOJGContractDocument);
}

export default function* rootSaga() {
  yield all([
    fork(watchCOJGContractDocumentByQuery),
    fork(watchCOJGContractDocumentById),
    fork(wathcEditEmpoyerdocument),
    fork(wathcUploadEmpoyerdocument),
    fork(watchDeleteCOJGContractDocument),
  ]);
}
