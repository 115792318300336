import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  GET_EMPLOYER_DOCUMENTS_BY_QUERY,
  GET_EMPLOYER_DOCUMENTS_BY_ID,
  EDIT_EMPLOYER_DOCUMENTS,
  UPLOAD_EMPLOYER_DOCUMENTS,
  DELETE_EMPLOYER_DOCUMENTS,
} from './types';

import {
  getEmployerDocumentByIdSuccess,
  getEmloyerDocumentByQuerySuccess,
  employerDocumentError,
} from './actions';

const getEmloyerDocumentByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/EmployerDocument/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getEmployerDocumentByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/EmployerDocument/${id}`, await authHeader());
};

const editEmployerDocumentRequest = async employer => {
  return await axios.put(
    `${Config.apiServerHost}/api/EmployerDocument`,
    employer,
    await authHeader()
  );
};

const uploadEmployerDocumentRequest = async (document, employerId) => {
  return await axios.put(
    `${Config.apiServerHost}/api/EmployerDocument/uploadDocument/${employerId}`,
    document,
    await authHeader()
  );
};

const deleteEmployerDocumentRequest = async id => {
  return await axios.delete(
    `${Config.apiServerHost}/api/EmployerDocument/${id}`,
    await authHeader()
  );
};

function* getEmloyerDocumentByQuery({ payload }) {
  try {
    const response = yield call(getEmloyerDocumentByQueryRequest, payload);
    yield put(getEmloyerDocumentByQuerySuccess(response.data));
  } catch (error) {
    yield put(employerDocumentError(error));
  }
}

function* getEmployerDocumentById({ payload }) {
  try {
    const response = yield call(getEmployerDocumentByIdRequest, payload);
    yield put(getEmployerDocumentByIdSuccess(response.data));
  } catch (error) {
    yield put(employerDocumentError(error));
  }
}

function* editEmployerDocument({ payload }) {
  try {
    const { employerId, data } = payload;
    const query = {
      employerId,
    };
    yield call(editEmployerDocumentRequest, data);
    const response = yield call(getEmloyerDocumentByQueryRequest, query);
    yield put(getEmloyerDocumentByQuerySuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(employerDocumentError(error));
  }
}

function* uploadEmployerDocument({ payload }) {
  try {
    const { document, employerId } = payload;
    const query = {
      employerId,
    };
    yield call(uploadEmployerDocumentRequest, document, employerId);
    const response = yield call(getEmloyerDocumentByQueryRequest, query);
    yield put(getEmloyerDocumentByQuerySuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(employerDocumentError(error));
  }
}

function* deleteEmployerDocument({ payload }) {
  try {
    const { employerId, data } = payload;
    const query = {
      employerId,
    };

    yield call(deleteEmployerDocumentRequest, data);
    const response = yield call(getEmloyerDocumentByQueryRequest, query);
    yield put(getEmloyerDocumentByQuerySuccess(response.data));
  } catch (error) {
    yield put(employerDocumentError(error));
  }
}

export function* watchEmployerDocumentByQuery() {
  yield takeEvery(GET_EMPLOYER_DOCUMENTS_BY_QUERY, getEmloyerDocumentByQuery);
}

export function* watchEmployerDocumentById() {
  yield takeEvery(GET_EMPLOYER_DOCUMENTS_BY_ID, getEmployerDocumentById);
}

export function* wathcEditEmpoyerdocument() {
  yield takeEvery(EDIT_EMPLOYER_DOCUMENTS, editEmployerDocument);
}

export function* wathcUploadEmpoyerdocument() {
  yield takeEvery(UPLOAD_EMPLOYER_DOCUMENTS, uploadEmployerDocument);
}

export function* watchDeleteEmployerDocument() {
  yield takeEvery(DELETE_EMPLOYER_DOCUMENTS, deleteEmployerDocument);
}

export default function* rootSaga() {
  yield all([
    fork(watchEmployerDocumentByQuery),
    fork(watchEmployerDocumentById),
    fork(wathcEditEmpoyerdocument),
    fork(wathcUploadEmpoyerdocument),
    fork(watchDeleteEmployerDocument),
  ]);
}
