import {
  IES_CONTRACT_GET_LIST,
  IES_CONTRACT_GET_LIST_SUCCESS,
  IES_CONTRACT_GET_LIST_ERROR,
  IES_CONTRACT_GET_BY_ID,
  IES_CONTRACT_GET_BY_ID_SUCCESS,
  IES_CONTRACT_GET_BY_ID_ERROR,
  IES_CONTRACT_ADD_ITEM,
  IES_CONTRACT_ADD_ITEM_SUCCESS,
  IES_CONTRACT_ADD_ITEM_ERROR,
  IES_CONTRACT_EDIT_ITEM,
  IES_CONTRACT_EDIT_ITEM_SUCCESS,
  IES_CONTRACT_EDIT_ITEM_ERROR,
  IES_CONTRACT_DELETE_ITEM,
  IES_CONTRACT_DELETE_ITEM_SUCCESS,
  IES_CONTRACT_DELETE_ITEM_ERROR,
} from '../actions';

//iesContract
const initialState = {
  iesContracts: [],
  esContract: null,
  error: '',
  loading: false,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default function reducerState(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case IES_CONTRACT_GET_BY_ID:
    case IES_CONTRACT_GET_LIST:
      return {
        ...state,
        loading: true,
        editSuccess: false,
      };

    case IES_CONTRACT_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        iesContracts: payload,
      };

    case IES_CONTRACT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        iesContract: payload,
      };

    case IES_CONTRACT_ADD_ITEM:
      return {
        ...state,
        loading: true,
        addSuccess: false,
      };

    case IES_CONTRACT_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        iesContracts: payload,
        addSuccess: true,
      };
    case IES_CONTRACT_EDIT_ITEM:
      return {
        ...state,
        loading: true,
        editSuccess: false,
      };

    case IES_CONTRACT_EDIT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        iesContracts: payload,
        editSuccess: true,
      };

    case IES_CONTRACT_DELETE_ITEM:
      return {
        ...state,
        loading: true,
        deleteSuccess: false,
      };
    case IES_CONTRACT_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        iesContracts: payload,
        deleteSuccess: true,
      };

    case IES_CONTRACT_GET_LIST_ERROR:
    case IES_CONTRACT_ADD_ITEM_ERROR:
    case IES_CONTRACT_EDIT_ITEM_ERROR:
    case IES_CONTRACT_DELETE_ITEM_ERROR:
    case IES_CONTRACT_GET_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
