import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CEC_LIST_DELETE,
  CEC_LIST_EDIT,
  CEC_LIST_GET_BY_ID,
  CEC_LIST_ADD_NEW,
  CEC_LIST_GET_LIST,
  CEC_LIST_BULK_DELETE,
} from '../actions';
import Config from '../../config';

import {
  getCECListListSuccess,
  getCECListListError,
  editCECListSuccess,
  editCECListError,
  addNewCECListSuccess,
  addNewCECListError,
  deleteCECListSuccess,
  deleteCECListError,
  getCECListByIdSuccess,
  bulkDeleteCECListError,
  bulkDeleteCECListSuccess,
} from './actions';

const getCECListListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/CECListing`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getCECListListByQueryRequest = async payload => {
  let url = `${Config.apiServerHost}/api/CECListing/search`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewCECListReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/CECListing`, item, await authHeader());
};

const editCECListRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/CECListing`, item, await authHeader());
};

const deleteCECListRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/CECListing/${id}`, await authHeader());
};

const getCECListByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/CECListing/${id}`, await authHeader());
};

const bulkDeleteCECListRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/CECListing/bulkDelete`, item, await authHeader());
};

function* getCECListList({ payload }) {
  try {
    const response = yield call(getCECListListByQueryRequest, payload);
    yield put(getCECListListSuccess(response.data));
  } catch (error) {
    yield put(getCECListListError(error.response.statusText));
  }
}

function* addNewCECList({ payload }) {
  try {
    let response = yield call(addNewCECListReqeust, payload);
    response = yield call(getCECListByIdRequest, response.data);
    yield put(addNewCECListSuccess(response.data));
  } catch (error) {
    yield put(addNewCECListError(error.response.statusText));
  }
}

function* editCECList({ payload }) {
  try {
    let response = yield call(editCECListRequest, payload);
    response = yield call(getCECListByIdRequest, response.data);
    yield put(editCECListSuccess(response.data));
  } catch (error) {
    yield put(editCECListError(error.response.statusText));
  }
}

function* getCECListById({ payload }) {
  try {
    const response = yield call(getCECListByIdRequest, payload);
    yield put(getCECListByIdSuccess(response.data));
  } catch (error) {
    yield put(editCECListError(error.response.statusText));
  }
}

function* deleteCECList({ payload }) {
  try {
    const response = yield call(deleteCECListRequest, payload);
    yield put(deleteCECListSuccess(response.data));
  } catch (error) {
    yield put(deleteCECListError(error.response.statusText));
  }
}

function* bulkDeleteCECList({ payload }) {
  try {
    const response = yield call(bulkDeleteCECListRequest, payload);
    yield put(bulkDeleteCECListSuccess(response.data));
  } catch (error) {
    yield put(bulkDeleteCECListError(error.response.statusText));
  }
}

export function* watchCECListList() {
  yield takeEvery(CEC_LIST_GET_LIST, getCECListList);
}

export function* watchAddNewCECList() {
  yield takeEvery(CEC_LIST_ADD_NEW, addNewCECList);
}

export function* watchCECListById() {
  yield takeEvery(CEC_LIST_GET_BY_ID, getCECListById);
}

export function* watchEditCECList() {
  yield takeEvery(CEC_LIST_EDIT, editCECList);
}

export function* watchDeleteCECList() {
  yield takeEvery(CEC_LIST_DELETE, deleteCECList);
}

export function* watchBulkDeleteCECList() {
  yield takeEvery(CEC_LIST_BULK_DELETE, bulkDeleteCECList);
}

export default function* rootSaga() {
  yield all([
    fork(watchCECListList),
    fork(watchAddNewCECList),
    fork(watchCECListById),
    fork(watchEditCECList),
    fork(watchDeleteCECList),
    fork(watchBulkDeleteCECList),
  ]);
}

export { getCECListListRequest, editCECListRequest, getCECListListByQueryRequest };
