import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from './text';

const propTypes = {
  newTab: PropTypes.bool,
};

const linkVariants = ({ theme, variant, isSelected }) => {
  if (!variant) return '';

  const { space, colors } = theme;

  let styles;

  switch (variant) {
    case 'navbar':
      styles = `
        color: ${isSelected ? colors.primary[0] : colors.greys[4]};
        padding: ${space[1]} ${space[2]};
        transition: color 0.3s;

        :hover {
          color: ${isSelected ? colors.primary[0] : colors.greys[3]};
          text-decoration: none;
        }
      `;
      break;

    case 'profile':
      styles = `
        :hover {
          text-decoration: none;
        }
        
      `;
      break;
    default:
      break;
  }

  return styles;
};

const StyledLink = styled(Text)`
  &:hover {
    text-decoration: underline;
    color: ${({ theme, color }) => theme.colors[color]};
  }

  ${linkVariants}
`;

export const Link = ({ newTab, ...props }) => {
  let tabProps = {};

  if (newTab) {
    tabProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }

  return <StyledLink {...props} {...tabProps} />;
};

Link.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  as: 'a',
  newTab: false,
};

Link.propTypes = propTypes;
