import {
  JOB_MATCHING_ADD_NEW,
  JOB_MATCHING_DELETE,
  JOB_MATCHING_EDIT,
  JOB_MATCHING_GET_BY_ID,
  JOB_MATCHING_GET_BY_ID_SUCCESS,
  JOB_MATCHING_GET_LIST,
  JOB_MATCHING_GET_LIST_SUCCESS,
  JOB_MATCHING_RESULT_CHANGE,
  JOB_MATCHING_ADD_NEW_REQUEST,
  JOB_MATCHING_ERROR,
} from './types';
// JobMatching
export const getJobMatchingList = query => ({
  type: JOB_MATCHING_GET_LIST,
  payload: query,
});

export const getJobMatchingListSuccess = item => ({
  type: JOB_MATCHING_GET_LIST_SUCCESS,
  payload: item,
});

export const addNewJobMatching = item => ({
  type: JOB_MATCHING_ADD_NEW,
  payload: item,
});

export const addNewJobMatchingRequest = () => ({
  type: JOB_MATCHING_ADD_NEW_REQUEST,
});

export const editJobMatching = item => ({
  type: JOB_MATCHING_EDIT,
  payload: item,
});

export const deleteJobMatching = id => ({
  type: JOB_MATCHING_DELETE,
  payload: id,
});

export const getJobMatchingById = id => ({
  type: JOB_MATCHING_GET_BY_ID,
  payload: id,
});

export const getJobMatchingByIdSuccess = item => ({
  type: JOB_MATCHING_GET_BY_ID_SUCCESS,
  payload: item,
});

export const jobMatchingError = error => ({
  type: JOB_MATCHING_ERROR,
  payload: error,
});
