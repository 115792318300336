import styled from 'styled-components';
import {
  color,
  position,
  space,
  layout,
  flexbox,
  border,
  overflow,
  boxShadow,
  textAlign,
  justifyContent,
  typography,
} from 'styled-system';

export const Box = styled.div`
  ${typography}
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  ${overflow}
  ${boxShadow}
  ${textAlign}
  ${justifyContent}
`;

Box.defaultProps = {
  display: 'flex',
};
