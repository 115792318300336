import {
  USER_GET_LIST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_ERROR,
  USER_GET_LIST_SEARCH,
  USER_SELECTED_ITEMS_CHANGE,
  USER_DELETE_ITEM,
  USER_DELETE_ITEM_SUCCESS,
  USER_DELETE_ITEM_ERROR,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_SUCCESS,
  USER_ROLE_GET_BY_ID,
  USER_ROLE_GET_BY_ID_SUCCESS,
  USER_ROLE_GET_BY_ID_ERROR,
  USER_ROLE_UPDATE,
  USER_ROLE_UPDATE_ERROR,
  USER_ROLE_UPDATE_SUCCESS,
  USER_ROLE_DELETE_LIST,
  USER_ROLE_DELETE_LIST_ERROR,
  USER_ROLE_DELETE_LIST_SUCCESS,
  USER_ADD_OR_UPDATE_ITEM_ERROR,
  USER_PROFILE_GET_BY_ID_SUCCESS,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_DELETE_LIST_SUCCESS,
  USER_PROFILE_PICTURE_UPDATE,
  USER_PROFILE_PICTURE_UPDATE_SUCCESS,
  USER_PROFILE_CLOSE_ERROR_MESSAGE,
  USER_DISABLED_OR_ENABLED,
  USER_DISABLED_OR_ENABLED_ERROR,
  USER_DISABLED_OR_ENABLED_SUCCESS,
} from '../actions';
import { get } from 'lodash';

const INIT_STATE = {
  userLists: [],
  userList: [],
  userRoles: [],
  userRole: [],
  userProfiles: [],
  userProfile: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  selectedItems: [],
  pageCount: 0,
  itemCount: 0,
};

export default function iniState(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (action.type) {
    case USER_GET_LIST:
    case USER_RESET_PASSWORD:
    case USER_ROLE_UPDATE:
    case USER_ROLE_DELETE_LIST:
    case USER_PROFILE_PICTURE_UPDATE:
      return { ...state, loading: false };

    case USER_DISABLED_OR_ENABLED_SUCCESS:
    case USER_GET_LIST_SUCCESS:
    case USER_RESET_PASSWORD_SUCCESS:
      const { pageCount, itemCount, data } = payload;
      return {
        ...state,
        loading: true,
        userLists: data,
        userList: data,
        pageCount,
        itemCount,
      };
    case USER_ROLE_UPDATE_SUCCESS:
    case USER_ROLE_DELETE_LIST_SUCCESS:
      return { ...state, loading: true, userRoles: action.payload, userRole: action.payload };

    case USER_PROFILE_UPDATE_SUCCESS:
    case USER_PROFILE_DELETE_LIST_SUCCESS:
    case USER_PROFILE_PICTURE_UPDATE_SUCCESS:
      return { ...state, loading: true, userProfiles: action.payload, userProfile: action.payload };

    case USER_GET_LIST_ERROR:
    case USER_RESET_PASSWORD_ERROR:
    case USER_ROLE_UPDATE_ERROR:
    case USER_ROLE_DELETE_LIST_ERROR:
      return {
        ...state,
        loading: true,
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };
    case USER_PROFILE_CLOSE_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case USER_ADD_OR_UPDATE_ITEM_ERROR:
      return {
        ...state,
        loading: true,
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };

    case USER_DISABLED_OR_ENABLED:
    case USER_ROLE_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case USER_ROLE_GET_BY_ID_SUCCESS:
      return { ...state, loading: true, userRoles: action.payload, userRole: action.payload };

    case USER_PROFILE_GET_BY_ID_SUCCESS:
      return { ...state, loading: true, userProfiles: action.payload, userProfile: action.payload };

    case USER_ROLE_GET_BY_ID_ERROR:
      return {
        ...state,
        loading: true,
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };

    case USER_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, userList: state.userLists };
      } else {
        const keyword = action.payload.toLowerCase();
        const searchItems = state.userLists.filter(
          item =>
            String(item.id).startsWith(keyword) ||
            String(item.name.toLowerCase()).startsWith(keyword)
        );
        return { ...state, loading: true, userList: searchItems, searchKeyword: action.payload };
      }

    case USER_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectADD_OR_UPDATEems: action.payload };
    default:
      return { ...state };

    case USER_DELETE_ITEM:
      return { ...state, loading: false };

    case USER_DELETE_ITEM_SUCCESS:
      return { ...state, loading: true, userLists: action.payload, userList: action.payload };

    case USER_DISABLED_OR_ENABLED_ERROR:
    case USER_DELETE_ITEM_ERROR:
      return {
        ...state,
        loading: true,
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };
  }
}
