import {
  CLIENT_GET_LIST,
  CLIENT_GET_LIST_SUCCESS,
  CLIENT_GET_LIST_ERROR,
  CLIENT_GET_BY_ID,
  CLIENT_GET_BY_ID_SUCCESS,
  CLIENT_GET_BY_ID_ERROR,
  CLIENT_ADD_NEW,
  CLIENT_ADD_NEW_SUCCESS,
  CLIENT_ADD_NEW_ERROR,
  CLIENT_EDIT,
  CLIENT_EDIT_SUCCESS,
  CLIENT_EDIT_ERROR,
  CLIENT_DELETE,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_ERROR,
  CLIENT_DELETE_REQUEST,
  CLIENT_FILTER,
  CLIENT_FILTER_SUCCESS,
  CLIENT_FILTER_ERROR,
  CLIENT_EDIT_REQUEST,
  NOTIFICATION_GET_LIST_SUCCESS,
  CLIENT_MANAGER_VIEW_UPDATE,
  CLIENT_COMPLETED_RESULT,
  GENERATE_CLIENT_REPORT,
  GENERATE_CLIENT_REPORT_SUCCESS,
  GENERATE_CLIENT_REPORT_ERROR,
  CLIENT_FILE_ACIVITY_UPDATE,
  CLIENT_FILE_ACIVITY_UPDATE_SUCCESS,
  CLIENT_FILE_ACIVITY_UPDATE_ERROR,
  CLIENT_FILE_ACIVITY_HUB_UPDATE,
  CLIENT_FILE_ACIVITY_HUB_UPDATE_SUCCESS,
  CLIENT_FILE_ACIVITY_HUB_UPDATE_ERROR,
  GET_CLIENT_FILE_ACIVITY,
  GET_CLIENT_FILE_ACIVITY_SUCCESS,
  GET_CLIENT_FILE_ACIVITY_ERROR,
  GET_CLIENT_FILE_ACIVITY_HUB,
  GET_CLIENT_FILE_ACIVITY_HUB_SUCCESS,
  GET_CLIENT_FILE_ACIVITY_HUB_ERROR,
  ADD_NEW_CLIENT_PARTICIPANT,
  SAVE_CLIENT_PARTICIPANT,
  SAVE_CLIENT_PARTICIPANT_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_ERROR,
  SAVE_CLIENT_PARTICIPANT_ARI,
  SAVE_CLIENT_PARTICIPANT_ARI_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_ARI_ERROR,
  SAVE_CLIENT_PARTICIPANT_HUB,
  SAVE_CLIENT_PARTICIPANT_HUB_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_HUB_ERROR,
  SAVE_CLIENT_PARTICIPANT_LBS,
  SAVE_CLIENT_PARTICIPANT_LBS_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_LBS_ERROR,
  GET_CLIENT_PARTICIPANT,
  GET_CLIENT_PARTICIPANT_SUCCESS,
  GET_CLIENT_PARTICIPANT_ERROR,
  GET_CLIENT_PARTICIPANT_HUB,
  GET_CLIENT_PARTICIPANT_HUB_SUCCESS,
  GET_CLIENT_PARTICIPANT_HUB_ERROR,
  GET_CLIENT_PARTICIPANT_ARI,
  GET_CLIENT_PARTICIPANT_ARI_SUCCESS,
  GET_CLIENT_PARTICIPANT_ARI_ERROR,
  GET_CLIENT_PARTICIPANT_LBS,
  GET_CLIENT_PARTICIPANT_LBS_SUCCESS,
  GET_CLIENT_PARTICIPANT_LBS_ERROR,
  SAVE_CLIENT_ASSESSMENT,
  SAVE_CLIENT_ASSESSMENT_SUCCESS,
  SAVE_CLIENT_ASSESSMENT_ERROR,
  SAVE_CLIENT_SPECIFIC_ASSESSMENT,
  SAVE_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS,
  SAVE_CLIENT_SPECIFIC_ASSESSMENT_ERROR,
  SAVE_CLIENT_ASSESSMENT_LBS,
  SAVE_CLIENT_ASSESSMENT_LBS_SUCCESS,
  SAVE_CLIENT_ASSESSMENT_LBS_ERROR,
  GET_CLIENT_ASSESSMENT_LBS,
  GET_CLIENT_ASSESSMENT_LBS_SUCCESS,
  GET_CLIENT_ASSESSMENT_LBS_ERROR,
  GET_CLIENT_ASSESSMENT,
  GET_CLIENT_ASSESSMENT_SUCCESS,
  GET_CLIENT_ASSESSMENT_ERROR,
  GET_CLIENT_SPECIFIC_ASSESSMENT,
  GET_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS,
  GET_CLIENT_SPECIFIC_ASSESSMENT_ERROR,
  CLIENT_CLOSED_NOTIFICATION_UPDATE,
  CLIENT_ERROR_REPORT,
  CLIENT_ERROR_REPORT_SUCCESS,
  CLIENT_ERROR_REPORT_ERROR,
  CLIENT_CLOSED_NOTIFICATION_ERROR,
  UNSUBSCRIBED_CLIENT_DATA,
  GENERATE_PERFORMANCE_INDICATOR_REPORT,
  GENERATE_PERFORMANCE_INDICATOR_REPORT_SUCCESS,
  GENERATE_PERFORMANCE_INDICATOR_REPORT_ERROR,
  CLIENT_SAVE_LEARNER_PLAN,
  CLIENT_SAVE_LEARNER_PLAN_SUCCESS,
  CLIENT_SAVE_LEARNER_PLAN_ERROR,
  CLIENT_SAVE_LEARNER_PLAN_LBS,
  CLIENT_SAVE_LEARNER_PLAN_LBS_SUCCESS,
  CLIENT_SAVE_LEARNER_PLAN_LBS_ERROR,
  CLIENT_GET_LEARNER_PLAN,
  CLIENT_GET_LEARNER_PLAN_SUCCESS,
  CLIENT_GET_LEARNER_PLAN_ERROR,
  GET_STUDENT_CHECKLIST_LBS,
  GET_STUDENT_CHECKLIST_LBS_SUCCESS,
  GET_STUDENT_CHECKLIST_LBS_ERROR,
  SAVE_STUDENT_CHECKLIST_LBS,
  SAVE_STUDENT_CHECKLIST_LBS_ERROR,
  SAVE_STUDENT_CHECKLIST_LBS_SUCCESS,
  GET_CLIENT_REDIRECT,
  GET_CLIENT_SERVICEPLANS,
  GET_CLIENT_SERVICEPLANS_SUCCESS,
  GET_CLIENT_SERVICEPLANS_ERROR,
  GET_CLIENT_SERVICEPLAN,
  GET_CLIENT_SERVICEPLAN_SUCCESS,
  GET_CLIENT_SERVICEPLAN_ERROR,
  ADD_CLIENT_SERVICEPLANS,
  ADD_CLIENT_SERVICEPLANS_SUCCESS,
  ADD_CLIENT_SERVICEPLANS_ERROR,
  UPDATE_CLIENT_SERVICEPLAN,
  UPDATE_CLIENT_SERVICEPLAN_SUCCESS,
  UPDATE_CLIENT_SERVICEPLAN_ERROR,
  GET_CLIENT_KPI,
  GET_CLIENT_KPI_SUCCESS,
} from '../actions';

export const getNotificationListSuccess = notifications => ({
  type: NOTIFICATION_GET_LIST_SUCCESS,
  payload: notifications,
});
export const getClientList = () => ({
  type: CLIENT_GET_LIST,
});

export const getClientListSuccess = clients => ({
  type: CLIENT_GET_LIST_SUCCESS,
  payload: clients,
});

export const getClientListError = error => ({
  type: CLIENT_GET_LIST_ERROR,
  payload: error,
});

export const getClientById = id => ({
  type: CLIENT_GET_BY_ID,
  payload: id,
});

export const getClientByIdSuccess = client => ({
  type: CLIENT_GET_BY_ID_SUCCESS,
  payload: client,
});

export const getClientByIdError = error => ({
  type: CLIENT_GET_BY_ID_ERROR,
  payload: error,
});

export const addNewClient = (client, history, userId) => ({
  type: CLIENT_ADD_NEW,
  payload: { client, history, userId },
});

export const addNewClientSuccess = client => ({
  type: CLIENT_ADD_NEW_SUCCESS,
  payload: client,
});

export const addNewClientError = error => ({
  type: CLIENT_ADD_NEW_ERROR,
  payload: error,
});

export const addNewParticipant = () => ({
  type: ADD_NEW_CLIENT_PARTICIPANT,
});

export const editClient = (client, history) => ({
  type: CLIENT_EDIT,
  payload: { client, history },
});

export const editClientSuccess = client => ({
  type: CLIENT_EDIT_SUCCESS,
  payload: client,
});

export const editClientError = error => ({
  type: CLIENT_EDIT_ERROR,
  payload: error,
});

export const deleteClientById = id => ({
  type: CLIENT_DELETE,
  payload: id,
});

export const deleteClientRequest = () => ({
  type: CLIENT_DELETE_REQUEST,
});

export const deleteClientByIdSuccess = client => ({
  type: CLIENT_DELETE_SUCCESS,
  payload: client,
});

export const deleteClientByIdError = error => ({
  type: CLIENT_DELETE_ERROR,
  payload: error,
});

export const getClientFilter = filter => ({
  type: CLIENT_FILTER,
  payload: filter,
});

export const getClientFilterSuccess = clients => ({
  type: CLIENT_FILTER_SUCCESS,
  payload: clients,
});

export const getClientFilterError = error => ({
  type: CLIENT_FILTER_ERROR,
  payload: error,
});

export const editClientRequest = (id, history) => ({
  type: CLIENT_EDIT_REQUEST,
  payload: { id, history },
});

export const ManagerViewUpdate = (client, userId) => ({
  type: CLIENT_MANAGER_VIEW_UPDATE,
  payload: { client, userId },
});

export const clientCompletedResult = client => ({
  type: CLIENT_COMPLETED_RESULT,
  payload: { client },
});

export const generateClientReport = param => ({
  type: GENERATE_CLIENT_REPORT,
  payload: param,
});

export const generateClientReportSuccess = data => ({
  type: GENERATE_CLIENT_REPORT_SUCCESS,
  payload: data,
});

export const generateClientReportError = error => ({
  type: GENERATE_CLIENT_REPORT_ERROR,
  payload: error,
});

export const generateClientErrorReport = params => ({
  type: CLIENT_ERROR_REPORT,
  payload: params,
});

export const generateClientErrorReportSuccess = data => ({
  type: CLIENT_ERROR_REPORT_SUCCESS,
  payload: data,
});

export const generateClientErrorReportError = error => ({
  type: CLIENT_ERROR_REPORT_ERROR,
  payload: error,
});

export const updateClientFileActivity = client => ({
  type: CLIENT_FILE_ACIVITY_UPDATE,
  payload: client,
});

export const updateClientFileActivitySuccess = data => ({
  type: CLIENT_FILE_ACIVITY_UPDATE_SUCCESS,
  payload: data,
});

export const updateClientFileActivityError = error => ({
  type: CLIENT_FILE_ACIVITY_UPDATE_ERROR,
  payload: error,
});

export const updateClientFileActivityHub = client => ({
  type: CLIENT_FILE_ACIVITY_HUB_UPDATE,
  payload: client,
});

export const updateClientFileActivityHubSuccess = data => ({
  type: CLIENT_FILE_ACIVITY_HUB_UPDATE_SUCCESS,
  payload: data,
});

export const updateClientFileActivityHubError = error => ({
  type: CLIENT_FILE_ACIVITY_HUB_UPDATE_ERROR,
  payload: error,
});

export const getClientFileActivity = query => ({
  type: GET_CLIENT_FILE_ACIVITY,
  payload: query,
});

export const getClientFileActivitySuccess = data => ({
  type: GET_CLIENT_FILE_ACIVITY_SUCCESS,
  payload: data,
});

export const getClientFileActivityError = error => ({
  type: GET_CLIENT_FILE_ACIVITY_ERROR,
  payload: error,
});

export const getClientFileActivityHub = query => ({
  type: GET_CLIENT_FILE_ACIVITY_HUB,
  payload: query,
});

export const getClientFileActivityHubSuccess = data => ({
  type: GET_CLIENT_FILE_ACIVITY_HUB_SUCCESS,
  payload: data,
});

export const getClientFileActivityHubError = error => ({
  type: GET_CLIENT_FILE_ACIVITY_HUB_ERROR,
  payload: error,
});

export const saveClientParticipant = (client, history, activeProgram) => ({
  type: SAVE_CLIENT_PARTICIPANT,
  payload: { client, history, activeProgram },
});

export const saveClientParticipantSuccess = data => ({
  type: SAVE_CLIENT_PARTICIPANT_SUCCESS,
  payload: data,
});

export const saveClientParticipantError = error => ({
  type: SAVE_CLIENT_PARTICIPANT_ERROR,
  payload: error,
});

// ARI
export const saveClientParticipantARI = (client, history, activeProgram) => ({
  type: SAVE_CLIENT_PARTICIPANT_ARI,
  payload: { client, history, activeProgram },
});

export const saveClientParticipantARISuccess = data => ({
  type: SAVE_CLIENT_PARTICIPANT_ARI_SUCCESS,
  payload: data,
});

export const saveClientParticipantARIError = error => ({
  type: SAVE_CLIENT_PARTICIPANT_ARI_ERROR,
  payload: error,
});

export const getClientParticipantARI = clientId => ({
  type: GET_CLIENT_PARTICIPANT_ARI,
  payload: clientId,
});

export const getClientParticipantARISuccess = data => ({
  type: GET_CLIENT_PARTICIPANT_ARI_SUCCESS,
  payload: data,
});

export const getClientParticipantARIError = error => ({
  type: GET_CLIENT_PARTICIPANT_ARI_ERROR,
  payload: error,
});

// LBS
export const saveClientParticipantLBS = (client, history, activeProgram) => ({
  type: SAVE_CLIENT_PARTICIPANT_LBS,
  payload: { client, history, activeProgram },
});

export const saveClientParticipantLBSSuccess = data => ({
  type: SAVE_CLIENT_PARTICIPANT_LBS_SUCCESS,
  payload: data,
});

export const saveClientParticipantLBSError = error => ({
  type: SAVE_CLIENT_PARTICIPANT_LBS_ERROR,
  payload: error,
});

export const getClientParticipantLBS = clientId => ({
  type: GET_CLIENT_PARTICIPANT_LBS,
  payload: clientId,
});

export const getClientParticipantLBSSuccess = data => ({
  type: GET_CLIENT_PARTICIPANT_LBS_SUCCESS,
  payload: data,
});

export const getClientParticipantLBSError = error => ({
  type: GET_CLIENT_PARTICIPANT_LBS_ERROR,
  payload: error,
});

//

export const saveClientParticipantHub = (client, history, activeProgram) => ({
  type: SAVE_CLIENT_PARTICIPANT_HUB,
  payload: { client, history, activeProgram },
});

export const saveClientParticipantHubSuccess = data => ({
  type: SAVE_CLIENT_PARTICIPANT_HUB_SUCCESS,
  payload: data,
});

export const saveClientParticipantHubError = error => ({
  type: SAVE_CLIENT_PARTICIPANT_HUB_ERROR,
  payload: error,
});

export const getClientParticipant = clientId => ({
  type: GET_CLIENT_PARTICIPANT,
  payload: clientId,
});

export const getClientParticipantSuccess = data => ({
  type: GET_CLIENT_PARTICIPANT_SUCCESS,
  payload: data,
});

export const getClientParticipantError = error => ({
  type: GET_CLIENT_PARTICIPANT_ERROR,
  payload: error,
});

//--
export const getClientServicePlans = clientId => ({
  type: GET_CLIENT_SERVICEPLANS,
  payload: clientId,
});

export const getClientServicePlansSuccess = data => ({
  type: GET_CLIENT_SERVICEPLANS_SUCCESS,
  payload: data,
});

export const getClientServicePlansError = error => ({
  type: GET_CLIENT_SERVICEPLANS_ERROR,
  payload: error,
});

export const getClientServicePlan = servicePlan => ({
  type: GET_CLIENT_SERVICEPLAN,
  payload: servicePlan,
});

export const getClientServicePlanSuccess = data => ({
  type: GET_CLIENT_SERVICEPLAN_SUCCESS,
  payload: data,
});

export const getClientServicePlanError = error => ({
  type: GET_CLIENT_SERVICEPLAN_ERROR,
  payload: error,
});

export const addClientServicePlans = clientId => ({
  type: ADD_CLIENT_SERVICEPLANS,
  payload: clientId,
});

export const addClientServicePlansSuccess = data => ({
  type: ADD_CLIENT_SERVICEPLANS_SUCCESS,
  payload: data,
});

export const addClientServicePlansError = error => ({
  type: ADD_CLIENT_SERVICEPLANS_ERROR,
  payload: error,
});

export const updateClientServicePlan = clientId => ({
  type: UPDATE_CLIENT_SERVICEPLAN,
  payload: clientId,
});

export const updateClientServicePlanSuccess = data => ({
  type: UPDATE_CLIENT_SERVICEPLAN_SUCCESS,
  payload: data,
});

export const updateClientServicePlanError = error => ({
  type: UPDATE_CLIENT_SERVICEPLAN_ERROR,
  payload: error,
});
//--

export const getClientParticipantHub = clientId => ({
  type: GET_CLIENT_PARTICIPANT_HUB,
  payload: clientId,
});

export const getClientParticipantHubSuccess = data => ({
  type: GET_CLIENT_PARTICIPANT_HUB_SUCCESS,
  payload: data,
});

export const getClientParticipantHubError = error => ({
  type: GET_CLIENT_PARTICIPANT_HUB_ERROR,
  payload: error,
});

export const saveClientAssessment = client => ({
  type: SAVE_CLIENT_ASSESSMENT,
  payload: client,
});

export const saveClientAssessmentSuccess = data => ({
  type: SAVE_CLIENT_ASSESSMENT_SUCCESS,
  payload: data,
});

export const saveClientAssessmentError = error => ({
  type: SAVE_CLIENT_ASSESSMENT_ERROR,
  payload: error,
});

export const saveClientSpecificAssessment = client => ({
  type: SAVE_CLIENT_SPECIFIC_ASSESSMENT,
  payload: client,
});

export const saveClientSpecificAssessmentSuccess = data => ({
  type: SAVE_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS,
  payload: data,
});

export const saveClientSpecificAssessmentError = error => ({
  type: SAVE_CLIENT_SPECIFIC_ASSESSMENT_ERROR,
  payload: error,
});

export const saveClientAssessmentLBS = client => ({
  type: SAVE_CLIENT_ASSESSMENT_LBS,
  payload: client,
});

export const saveClientAssessmentLBSSuccess = data => ({
  type: SAVE_CLIENT_ASSESSMENT_LBS_SUCCESS,
  payload: data,
});

export const saveClientAssessmentLBSError = error => ({
  type: SAVE_CLIENT_ASSESSMENT_LBS_ERROR,
  payload: error,
});

export const getClientAssessmentLBS = query => ({
  type: GET_CLIENT_ASSESSMENT_LBS,
  payload: query,
});

export const getClientAssessmentLBSSuccess = data => ({
  type: GET_CLIENT_ASSESSMENT_LBS_SUCCESS,
  payload: data,
});

export const getClientAssessmentLBSError = error => ({
  type: GET_CLIENT_ASSESSMENT_LBS_ERROR,
  payload: error,
});

export const getClientAssessment = query => ({
  type: GET_CLIENT_ASSESSMENT,
  payload: query,
});

export const getClientAssessmentSuccess = data => ({
  type: GET_CLIENT_ASSESSMENT_SUCCESS,
  payload: data,
});

export const getClientAssessmentError = error => ({
  type: GET_CLIENT_ASSESSMENT_ERROR,
  payload: error,
});

export const getClientSpecificAssessment = query => ({
  type: GET_CLIENT_SPECIFIC_ASSESSMENT,
  payload: query,
});

export const getClientSpecificAssessmentSuccess = data => ({
  type: GET_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS,
  payload: data,
});

export const getClientSpecificAssessmentError = error => ({
  type: GET_CLIENT_SPECIFIC_ASSESSMENT_ERROR,
  payload: error,
});

export const closeNotificationUpdateOnClient = () => ({
  type: CLIENT_CLOSED_NOTIFICATION_UPDATE,
});

export const closeNotificationErrorOnClient = () => ({
  type: CLIENT_CLOSED_NOTIFICATION_ERROR,
});

export const unsubscribedClientData = () => ({
  type: UNSUBSCRIBED_CLIENT_DATA,
});

export const generatePerformanceIndicatorReport = param => ({
  type: GENERATE_PERFORMANCE_INDICATOR_REPORT,
  payload: param,
});

export const generatePerformanceIndicatorReportSuccess = data => ({
  type: GENERATE_PERFORMANCE_INDICATOR_REPORT_SUCCESS,
  payload: data,
});

export const generatePerformanceIndicatorReportError = error => ({
  type: GENERATE_PERFORMANCE_INDICATOR_REPORT_ERROR,
  payload: error,
});

export const saveClientLearnerPlan = param => ({
  type: CLIENT_SAVE_LEARNER_PLAN,
  payload: param,
});

export const saveClientLearnerPlanSuccess = data => ({
  type: CLIENT_SAVE_LEARNER_PLAN_SUCCESS,
  payload: data,
});

export const saveClientLearnerPlanError = error => ({
  type: CLIENT_SAVE_LEARNER_PLAN_ERROR,
  payload: error,
});

export const saveClientLearnerPlanLBS = param => ({
  type: CLIENT_SAVE_LEARNER_PLAN_LBS,
  payload: param,
});

export const saveClientLearnerPlanLBSSuccess = data => ({
  type: CLIENT_SAVE_LEARNER_PLAN_LBS_SUCCESS,
  payload: data,
});

export const saveClientLearnerPlanLBSError = error => ({
  type: CLIENT_SAVE_LEARNER_PLAN_LBS_ERROR,
  payload: error,
});

export const getClientLearnerPlan = param => ({
  type: CLIENT_GET_LEARNER_PLAN,
  payload: param,
});

export const getClientLearnerPlanSuccess = data => ({
  type: CLIENT_GET_LEARNER_PLAN_SUCCESS,
  payload: data,
});

export const getClientLearnerPlanError = error => ({
  type: CLIENT_GET_LEARNER_PLAN_ERROR,
  payload: error,
});

export const saveStudentCheckListLBS = client => ({
  type: SAVE_STUDENT_CHECKLIST_LBS,
  payload: client,
});

export const saveStudentCheckListLBSSuccess = data => ({
  type: SAVE_STUDENT_CHECKLIST_LBS_SUCCESS,
  payload: data,
});

export const saveStudentCheckListLBSError = error => ({
  type: SAVE_STUDENT_CHECKLIST_LBS_ERROR,
  payload: error,
});

export const getStudentCheckListLBS = payload => ({
  type: GET_STUDENT_CHECKLIST_LBS,
  payload,
});

export const getStudentCheckListLBSSuccess = data => ({
  type: GET_STUDENT_CHECKLIST_LBS_SUCCESS,
  payload: data,
});

export const getStudentCheckListLBSError = error => ({
  type: GET_STUDENT_CHECKLIST_LBS_ERROR,
  payload: error,
});

export const getClientRedirect = data => ({
  type: GET_CLIENT_REDIRECT,
  payload: data,
});

export const getClientKPI = payload => ({
  type: GET_CLIENT_KPI,
  payload,
});

export const getClientKPISuccess = data => ({
  type: GET_CLIENT_KPI_SUCCESS,
  payload: data,
});
