import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import axios from 'axios';
import Config from '../../../config';

import {
  CHEQUE_GET_LIST,
  CHEQUE_ADD_ITEM,
  CHEQUE_EDIT_ITEM,
  CHEQUE_DELETE_ITEM,
  CHEQUE_GET_BY_ID,
} from '../../actions';

import {
  getChequeListSuccess,
  getChequeListError,
  addChequeItemSuccess,
  addChequeItemError,
  editChequeItemError,
  getChequeByIdSuccess,
  editChequeItemSuccess,
  deleteChequeItemSuccess,
  deleteChequeItemError,
  getChequeByIdError,
} from './actions';

const getChequeListRequest = async cojgId => {
  return await axios.get(
    `${Config.apiServerHost}/api/cheque/get-by-cojg-id/${cojgId}`,
    await authHeader()
  );
};

const addChequeItemRequest = async cheque => {
  return await axios.post(`${Config.apiServerHost}/api/cheque`, cheque, await authHeader());
};

const getChequeByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/cheque/${id}`, await authHeader());
};

const poshChequeListRequest = async cheque => {
  return await axios.post(`${Config.apiServerHost}/api/cheque`, cheque, await authHeader());
};

const updateChequeListRequest = async cheque => {
  return await axios.put(`${Config.apiServerHost}/api/cheque`, cheque, await authHeader());
};

const deleteChequeListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/cheque/${id}`, await authHeader());
};

function* getChequeListItems({ payload }) {
  try {
    const response = yield call(getChequeListRequest, payload);
    yield put(getChequeListSuccess(response.data));
  } catch (error) {
    yield put(getChequeListError(error));
  }
}

function* editChequeItem({ payload }) {
  try {
    let response = yield call(updateChequeListRequest, payload);
    response = yield call(getChequeByIdRequest, response.data);
    yield put(editChequeItemSuccess(response.data));
  } catch (error) {
    yield put(editChequeItemError(error));
  }
}

function* deleteChequeItem({ payload }) {
  try {
    let response = yield call(deleteChequeListRequest, payload);
    yield put(deleteChequeItemSuccess(response.data));
  } catch (error) {
    yield put(deleteChequeItemError(error));
  }
}

function* addChequeItem({ payload }) {
  try {
    let response = yield call(addChequeItemRequest, payload);
    response = yield call(getChequeByIdRequest, response.data);
    yield put(addChequeItemSuccess(response.data));
  } catch (error) {
    yield put(addChequeItemError(error));
  }
}

function* getChequeById({ payload }) {
  try {
    const response = yield call(getChequeByIdRequest, payload);
    yield put(getChequeByIdSuccess(response.data));
  } catch (error) {
    yield put(getChequeByIdError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CHEQUE_GET_LIST, getChequeListItems);
}

export function* watchChequeById() {
  yield takeEvery(CHEQUE_GET_BY_ID, getChequeById);
}

export function* wathcAddItem() {
  yield takeEvery(CHEQUE_ADD_ITEM, addChequeItem);
}
export function* wathcEditItem() {
  yield takeEvery(CHEQUE_EDIT_ITEM, editChequeItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(CHEQUE_DELETE_ITEM, deleteChequeItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchChequeById),
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(wathcDeleteItem),
  ]);
}
