import axios from 'axios';
import FileSaver from 'file-saver';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EMPLOYER_CASE_NOTE_DELETE,
  EMPLOYER_CASE_NOTE_EDIT,
  EMPLOYER_CASE_NOTE_GET_BY_ID,
  EMPLOYER_CASE_NOTE_ADD_NEW,
  EMPLOYER_CASE_NOTE_QUERY,
} from '../actions';

import {
  editEmployerCaseNoteSuccess,
  editEmployerCaseNoteError,
  addNewEmployerCaseNoteSuccess,
  addNewEmployerCaseNoteError,
  deleteEmployerCaseNoteSuccess,
  deleteEmployerCaseNoteError,
  getEmployerCaseNoteByIdSuccess,
  getEmployerCaseNoteQuerySuccess,
  getEmployerCaseNoteQueryError,
} from './actions';

const getEmployerCaseNoteQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/EmployerCaseNote/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const addNewEmployerCaseNoteReqeust = async EmployerCaseNote => {
  return axios.post(
    `${Config.apiServerHost}/api/EmployerCaseNote`,
    EmployerCaseNote,
    await authHeader()
  );
};

const editEmployerCaseNoteRequest = async EmployerCaseNote => {
  return axios.put(
    `${Config.apiServerHost}/api/EmployerCaseNote`,
    EmployerCaseNote,
    await authHeader()
  );
};

const exportCaseNotesToDoc = async (params, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(`${Config.apiServerHost}/api/EmployerCaseNote/exportToDoc`, params),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

const exportEmpoyerCaseNoteToDocById = async (id, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(`${Config.apiServerHost}/api/EmployerCaseNote/exportToDoc/${id}`),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

const deleteEmployerCaseNoteRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EmployerCaseNote/${id}`, await authHeader());
};

const getEmployerCaseNoteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmployerCaseNote/${id}`, await authHeader());
};

function* getEmployerCaseNoteQuery({ payload }) {
  try {
    const response = yield call(getEmployerCaseNoteQueryRequest, payload);
    yield put(getEmployerCaseNoteQuerySuccess(response.data));
  } catch (error) {
    yield put(getEmployerCaseNoteQueryError(error.response.statusText));
  }
}

function* addNewEmployerCaseNote({ payload }) {
  try {
    let response = yield call(addNewEmployerCaseNoteReqeust, payload);
    response = yield call(getEmployerCaseNoteByIdRequest, response.data);
    yield put(addNewEmployerCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(addNewEmployerCaseNoteError(error.response.statusText));
  }
}

function* editEmployerCaseNote({ payload }) {
  try {
    let response = yield call(editEmployerCaseNoteRequest, payload);
    response = yield call(getEmployerCaseNoteByIdRequest, payload.id);
    yield put(editEmployerCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(editEmployerCaseNoteError(error.response.statusText));
  }
}

function* getEmployerCaseNoteById({ payload }) {
  try {
    const response = yield call(getEmployerCaseNoteByIdRequest, payload);
    yield put(getEmployerCaseNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(editEmployerCaseNoteError(error.response.statusText));
  }
}

function* deleteEmployerCaseNote({ payload }) {
  try {
    const response = yield call(deleteEmployerCaseNoteRequest, payload);
    yield put(deleteEmployerCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(deleteEmployerCaseNoteError(error.response.statusText));
  }
}

export function* watchEmployerCaseNoteQuery() {
  yield takeEvery(EMPLOYER_CASE_NOTE_QUERY, getEmployerCaseNoteQuery);
}

export function* watchAddNewEmployerCaseNote() {
  yield takeEvery(EMPLOYER_CASE_NOTE_ADD_NEW, addNewEmployerCaseNote);
}

export function* watchEmployerCaseNoteById() {
  yield takeEvery(EMPLOYER_CASE_NOTE_GET_BY_ID, getEmployerCaseNoteById);
}

export function* watchEditEmployerCaseNote() {
  yield takeEvery(EMPLOYER_CASE_NOTE_EDIT, editEmployerCaseNote);
}

export function* watchDeleteEmployerCaseNote() {
  yield takeEvery(EMPLOYER_CASE_NOTE_DELETE, deleteEmployerCaseNote);
}

export default function* rootSaga() {
  yield all([
    fork(watchEmployerCaseNoteQuery),
    fork(watchAddNewEmployerCaseNote),
    fork(watchEmployerCaseNoteById),
    fork(watchEditEmployerCaseNote),
    fork(watchDeleteEmployerCaseNote),
  ]);
}

export { exportCaseNotesToDoc, exportEmpoyerCaseNoteToDocById };
