import {
  BUDGET_GET_LIST,
  BUDGET_GET_LIST_SUCCESS,
  BUDGET_GET_LIST_ERROR,
  BUDGET_GET_LIST_WITH_FILTER,
  BUDGET_GET_LIST_WITH_ORDER,
  BUDGET_GET_LIST_SEARCH,
  BUDGET_ADD_ITEM,
  BUDGET_ADD_ITEM_SUCCESS,
  BUDGET_ADD_ITEM_ERROR,
  BUDGET_SELECTED_ITEMS_CHANGE,
  BUDGET_DELETE_ITEM,
  BUDGET_DELETE_ITEM_SUCCESS,
  BUDGET_DELETE_ITEM_ERROR,
} from '../../actions';

const INIT_STATE = {
  budgets: null,
  budget: null,
  itemCount: 0,
  pageCount: 0,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case BUDGET_GET_LIST:
      return { ...state, loading: false };

    case BUDGET_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: true,
        budgets: data,
        itemCount,
        pageCount,
      };

    case BUDGET_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BUDGET_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return { ...state, loading: true, budget: state.budgets, filter: null };
      } else {
        const filteredItems = state.budgets.filter(
          item => item[action.payload.column] === action.payload.value
        );
        return {
          ...state,
          loading: true,
          budget: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case BUDGET_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return { ...state, loading: true, budget: state.budget, orderColumn: null };
      } else {
        const sortedItems = state.budget.sort((a, b) => {
          if (a[action.payload] < b[action.payload]) return -1;
          else if (a[action.payload] > b[action.payload]) return 1;
          return 0;
        });
        return {
          ...state,
          loading: true,
          budget: sortedItems,
          orderColumn: state.orderColumns.find(x => x.column === action.payload),
        };
      }

    case BUDGET_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, budget: state.budgets };
      } else {
        const keyword = action.payload;
        const searchItems = state.budgets.filter(
          item =>
            String(item.id).startsWith(keyword) ||
            String(item.program.toLowerCase()).startsWith(keyword) ||
            String(item.fiscal.toLowerCase()).startsWith(keyword) ||
            String(item.start).startsWith(keyword) ||
            String(item.contract).startsWith(keyword)
        );
        return { ...state, loading: true, budget: searchItems, searchKeyword: action.payload };
      }

    case BUDGET_ADD_ITEM:
      return { ...state, loading: false };

    case BUDGET_ADD_ITEM_SUCCESS:
      return { ...state, loading: true, budgets: action.payload, budget: action.payload };

    case BUDGET_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BUDGET_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload };
    default:
      return { ...state };

    case BUDGET_DELETE_ITEM:
      return { ...state, loading: false };

    case BUDGET_DELETE_ITEM_SUCCESS:
      return { ...state, loading: true, budgets: action.payload, budget: action.payload };

    case BUDGET_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
  }
};
