export default class Config {
  static get apiServerHost() {
    const hostname = window.location.hostname;
    let apiHost = hostname;
    if (hostname.indexOf('local') >= 0) {
      apiHost = 'localhost:5001';
    }
    if (apiHost.charAt(apiHost.length - 1) === '/') {
      apiHost = apiHost.substr(0, apiHost.length - 1);
    }
    return `https://${apiHost}`;
  }
}
