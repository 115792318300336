import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import ErrorBoundary from './components/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import { SettingsProvider } from './providers';
import { stopReportingRuntimeErrors } from 'react-error-overlay';

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors(); // disables error overlays
}

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <ErrorBoundary location={'root'}>
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </ErrorBoundary>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
