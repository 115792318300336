import Config from '../config';

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  // { id: 'es', name: 'Español', direction: 'ltr' },
  // { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  { id: 'fr', name: 'French', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';
export const endpointClientfund = `${Config.apiServerHost}/api/fileupload/client-fund-upload/`;
export const endpointClientfundGoProgram = `${Config.apiServerHost}/api/fileupload/client-fund-upload/`;
export const endpointClientSupportFileUpload = `${Config.apiServerHost}/api/fileupload/client-support-upload/`;
export const endpointFinancialTrackingAttachmentUpload = `${Config.apiServerHost}/api/fileupload/financial-tracking-attachment-upload/`;
export const endpointInterventionFollowupIESUpload = `${Config.apiServerHost}/api/fileupload/interventionFollowupIESUpload/`;
export const endpointAssessmentLBSSUpload = `${Config.apiServerHost}/api/fileupload/assessmentLBSUpload/`;
export const endpointESContractUpload = `${Config.apiServerHost}/api/fileupload/esContractUpload/`;

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

/**
 * App Registration Config
 */
export const authConfig = {
  scopes: ['User.Read', 'mailboxsettings.read', 'Calendars.ReadWrite'],
};
