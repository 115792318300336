import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_FUND_SETTING_DELETE,
  CLIENT_FUND_SETTING_EDIT,
  CLIENT_FUND_SETTING_GET_BY_ID,
  CLIENT_FUND_SETTING_ADD_NEW,
  CLIENT_FUND_SETTING_GET_LIST,
} from '../actions';

import {
  getClientFundSettingListSuccess,
  getClientFundSettingListError,
  editClientFundSettingSuccess,
  editClientFundSettingError,
  addNewClientFundSettingSuccess,
  addNewClientFundSettingError,
  deleteClientFundSettingSuccess,
  deleteClientFundSettingError,
  getClientFundSettingByIdSuccess,
} from './actions';

const getClientFundSettingListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/client/client-fund-setting`, await authHeader());
};

const addNewClientFundSettingReqeust = async ClientFundSetting => {
  return axios.post(
    `${Config.apiServerHost}/api/client/client-fund-setting`,
    ClientFundSetting,
    await authHeader()
  );
};

const editClientFundSettingRequest = async ClientFundSetting => {
  return axios.put(
    `${Config.apiServerHost}/api/client/client-fund-setting`,
    ClientFundSetting,
    await authHeader()
  );
};

const deleteClientFundSettingRequest = async id => {
  return axios.delete(
    `${Config.apiServerHost}/api/client/client-fund-setting/${id}`,
    await authHeader()
  );
};

const getClientFundSettingByIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/client/client-fund-setting/${id}`,
    await authHeader()
  );
};

function* getClientFundSettingList() {
  try {
    const response = yield call(getClientFundSettingListRequest);
    yield put(getClientFundSettingListSuccess(response.data));
  } catch (error) {
    yield put(getClientFundSettingListError(error.response.statusText));
  }
}

function* addNewClientFundSetting({ payload }) {
  try {
    const { ClientFundSetting, history } = payload;
    let response = yield call(addNewClientFundSettingReqeust, ClientFundSetting);
    response = yield call(getClientFundSettingByIdRequest, response.data);
    yield put(addNewClientFundSettingSuccess(response.data));
  } catch (error) {
    yield put(addNewClientFundSettingError(error.response.statusText));
  }
}

function* editClientFundSetting({ payload }) {
  try {
    const { ClientFundSetting, history } = payload;
    let response = yield call(editClientFundSettingRequest, ClientFundSetting);
    response = yield call(getClientFundSettingByIdRequest, ClientFundSetting.id);
    yield put(editClientFundSettingSuccess(response.data));
  } catch (error) {
    yield put(editClientFundSettingError(error.response.statusText));
  }
}

function* getClientFundSettingById({ payload }) {
  try {
    const response = yield call(getClientFundSettingByIdRequest, payload);
    yield put(getClientFundSettingByIdSuccess(response.data));
  } catch (error) {
    yield put(editClientFundSettingError(error.response.statusText));
  }
}

function* deleteClientFundSetting({ payload }) {
  try {
    const response = yield call(deleteClientFundSettingRequest, payload);
    yield put(deleteClientFundSettingSuccess(response.data));
  } catch (error) {
    yield put(deleteClientFundSettingError(error.response.statusText));
  }
}

export function* watchClientFundSettingList() {
  yield takeEvery(CLIENT_FUND_SETTING_GET_LIST, getClientFundSettingList);
}

export function* watchAddNewClientFundSetting() {
  yield takeEvery(CLIENT_FUND_SETTING_ADD_NEW, addNewClientFundSetting);
}

export function* watchClientFundSettingById() {
  yield takeEvery(CLIENT_FUND_SETTING_GET_BY_ID, getClientFundSettingById);
}

export function* watchEditClientFundSetting() {
  yield takeEvery(CLIENT_FUND_SETTING_EDIT, editClientFundSetting);
}

export function* watchDeleteClientFundSetting() {
  yield takeEvery(CLIENT_FUND_SETTING_DELETE, deleteClientFundSetting);
}

export default function* rootSaga() {
  yield all([
    fork(watchClientFundSettingList),
    fork(watchAddNewClientFundSetting),
    fork(watchClientFundSettingById),
    fork(watchEditClientFundSetting),
    fork(watchDeleteClientFundSetting),
  ]);
}
