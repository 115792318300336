import {
  CASE_NOTE2_ADD_NEW,
  CASE_NOTE2_ADD_NEW_SUCCESS,
  CASE_NOTE2_ADD_NEW_ERROR,
  CASE_NOTE2_DELETE,
  CASE_NOTE2_DELETE_ERROR,
  CASE_NOTE2_DELETE_SUCCESS,
  CASE_NOTE2_EDIT,
  CASE_NOTE2_EDIT_ERROR,
  CASE_NOTE2_EDIT_SUCCESS,
  CASE_NOTE2_GET_BY_ID,
  CASE_NOTE2_GET_BY_ID_ERROR,
  CASE_NOTE2_GET_BY_ID_SUCCESS,
  CASE_NOTE2_ADD_NEW_REQUEST,
  CASE_NOTE2_QUERY,
  CASE_NOTE2_QUERY_SUCCESS,
  CASE_NOTE2_QUERY_ERROR,
  CASE_NOTE2_AGGREGATE,
  CASE_NOTE2_AGGREGATE_ERROR,
  CASE_NOTE2_AGGREGATE_SUCCESS,
  EMPLOYER_CASE_NOTE2_EDIT,
  EMPLOYER_CASE_NOTE2_EDIT_SUCCESS,
  EMPLOYER_CASE_NOTE2_EDIT_ERROR,
  EMPLOYER_CASE_NOTE2_GET_BY_ID,
  EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS,
  EMPLOYER_CASE_NOTE2_GET_BY_ID_ERROR,
  CASE_NOTE2_NOTIFICATION_CLOSE,
} from '../actions';

export const getCaseNotesAggregate = query => ({
  type: CASE_NOTE2_AGGREGATE,
  payload: query,
});

export const getCaseNotesAggregateSuccess = caseNotes => ({
  type: CASE_NOTE2_AGGREGATE_SUCCESS,
  payload: caseNotes,
});

export const getCaseNotesAggregateError = error => ({
  type: CASE_NOTE2_AGGREGATE_ERROR,
  payload: error,
});

export const getCaseNote2Query = query => ({
  type: CASE_NOTE2_QUERY,
  payload: query,
});

export const getCaseNote2QuerySuccess = caseNotes => ({
  type: CASE_NOTE2_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getCaseNote2QueryError = error => ({
  type: CASE_NOTE2_QUERY_ERROR,
  payload: error,
});

export const addNewCaseNote2 = caseNote => ({
  type: CASE_NOTE2_ADD_NEW,
  payload: caseNote,
});

export const addNewCaseNote2Request = () => ({
  type: CASE_NOTE2_ADD_NEW_REQUEST,
});

export const addNewCaseNote2Success = CaseNote2 => ({
  type: CASE_NOTE2_ADD_NEW_SUCCESS,
  payload: CaseNote2,
});

export const addNewCaseNote2Error = error => ({
  type: CASE_NOTE2_ADD_NEW_ERROR,
  payload: error,
});

export const editCaseNote2 = CaseNote => ({
  type: CASE_NOTE2_EDIT,
  payload: CaseNote,
});

export const editCaseNote2Success = caseNote => ({
  type: CASE_NOTE2_EDIT_SUCCESS,
  payload: caseNote,
});

export const editCaseNote2Error = error => ({
  type: CASE_NOTE2_EDIT_ERROR,
  payload: error,
});

export const deleteCaseNote2 = id => ({
  type: CASE_NOTE2_DELETE,
  payload: id,
});

export const deleteCaseNote2Success = id => ({
  type: CASE_NOTE2_DELETE_SUCCESS,
  payload: id,
});

export const deleteCaseNote2Error = error => ({
  type: CASE_NOTE2_DELETE_ERROR,
  payload: error,
});

export const getCaseNote2ById = id => ({
  type: CASE_NOTE2_GET_BY_ID,
  payload: id,
});

export const getCaseNote2ByIdSuccess = caseNote => ({
  type: CASE_NOTE2_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getCaseNote2ByIdError = error => ({
  type: CASE_NOTE2_GET_BY_ID_ERROR,
  payload: error,
});

export const editEmployerCaseNote2 = (caseNote, query) => ({
  type: EMPLOYER_CASE_NOTE2_EDIT,
  payload: { caseNote, query },
});

export const editEmployerCaseNote2Success = caseNote => ({
  type: EMPLOYER_CASE_NOTE2_EDIT_SUCCESS,
  payload: caseNote,
});

export const editEmployerCaseNote2Error = error => ({
  type: EMPLOYER_CASE_NOTE2_EDIT_ERROR,
  payload: error,
});

export const getEmployerCaseNote2ById = id => ({
  type: EMPLOYER_CASE_NOTE2_GET_BY_ID,
  payload: id,
});

export const getEmployerCaseNote2ByIdSuccess = caseNote => ({
  type: EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getEmployerCaseNote2ByIdError = error => ({
  type: EMPLOYER_CASE_NOTE2_GET_BY_ID_ERROR,
  payload: error,
});

export const closeCaseNote2Notification = () => ({
  type: CASE_NOTE2_NOTIFICATION_CLOSE,
});
