import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CEC_CONTACT_DELETE,
  CEC_CONTACT_EDIT,
  CEC_CONTACT_GET_BY_ID,
  CEC_CONTACT_ADD_NEW,
  CEC_CONTACT_GET_LIST,
  CEC_CONTACT_BULK_DELETE,
  CEC_CONTACT_IMPORT_FROM_CLIENT,
  CEC_CONTACT_IMPORT_FROM_CSV,
} from '../actions';
import Config from '../../config';

import {
  getCECContactListSuccess,
  getCECContactListError,
  editCECContactSuccess,
  editCECContactError,
  addNewCECContactSuccess,
  addNewCECContactError,
  deleteCECContactSuccess,
  deleteCECContactError,
  getCECContactByIdSuccess,
  bulkDeleteCECContactError,
  bulkDeleteCECContactSuccess,
  importCECContactFromClientSuccess,
  importCECContactFromClientError,
  importCECContactFromCSVSuccess,
  importCECContactFromCSVError,
} from './actions';

const getCECContactListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/CECContact`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getCECContactListByQueryRequest = async payload => {
  let url = `${Config.apiServerHost}/api/CECContact/search`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewCECContactReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/CECContact`, item, await authHeader());
};

const editCECContactRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/CECContact`, item, await authHeader());
};

const deleteCECContactRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/CECContact/${id}`, await authHeader());
};

const getCECContactByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/CECContact/${id}`, await authHeader());
};

const bulkDeleteCECContactRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/CECContact/bulkDelete`, item, await authHeader());
};

const importCECContactFromClientRequest = async items => {
  return axios.post(
    `${Config.apiServerHost}/api/CECContact/importFromClient`,
    items,
    await authHeader()
  );
};

const importCECContactFromCSVRequest = async items => {
  return axios.post(
    `${Config.apiServerHost}/api/CECContact/importFromCSV`,
    items,
    await authHeader()
  );
};

function* getCECContactList({ payload }) {
  try {
    const response = yield call(getCECContactListByQueryRequest, payload);
    yield put(getCECContactListSuccess(response.data));
  } catch (error) {
    yield put(getCECContactListError(error.response.statusText));
  }
}

function* addNewCECContact({ payload }) {
  try {
    let response = yield call(addNewCECContactReqeust, payload);
    response = yield call(getCECContactByIdRequest, response.data);
    yield put(addNewCECContactSuccess(response.data));
  } catch (error) {
    yield put(addNewCECContactError(error.response.statusText));
  }
}

function* editCECContact({ payload }) {
  try {
    let response = yield call(editCECContactRequest, payload);
    response = yield call(getCECContactByIdRequest, response.data);
    yield put(editCECContactSuccess(response.data));
  } catch (error) {
    yield put(editCECContactError(error.response.statusText));
  }
}

function* getCECContactById({ payload }) {
  try {
    const response = yield call(getCECContactByIdRequest, payload);
    yield put(getCECContactByIdSuccess(response.data));
  } catch (error) {
    yield put(editCECContactError(error.response.statusText));
  }
}

function* deleteCECContact({ payload }) {
  try {
    const response = yield call(deleteCECContactRequest, payload);
    yield put(deleteCECContactSuccess(response.data));
  } catch (error) {
    yield put(deleteCECContactError(error.response.statusText));
  }
}

function* bulkDeleteCECContact({ payload }) {
  try {
    const response = yield call(bulkDeleteCECContactRequest, payload);
    yield put(bulkDeleteCECContactSuccess(response.data));
  } catch (error) {
    yield put(bulkDeleteCECContactError(error.response.statusText));
  }
}

function* importCECContactFromClient({ payload }) {
  try {
    yield call(importCECContactFromClientRequest, payload);
    const query = {
      query: '',
    };

    const response = yield call(getCECContactListByQueryRequest, query);
    yield put(importCECContactFromClientSuccess(response.data));
  } catch (error) {
    yield put(importCECContactFromClientError(error.response.statusText));
  }
}

function* importCECContactFromCSV({ payload }) {
  try {
    yield call(importCECContactFromCSVRequest, payload);
    const query = {
      query: '',
    };

    const response = yield call(getCECContactListByQueryRequest, query);
    yield put(importCECContactFromCSVSuccess(response.data));
  } catch (error) {
    yield put(importCECContactFromCSVError(error.response.statusText));
  }
}

export function* watchCECContactList() {
  yield takeEvery(CEC_CONTACT_GET_LIST, getCECContactList);
}

export function* watchAddNewCECContact() {
  yield takeEvery(CEC_CONTACT_ADD_NEW, addNewCECContact);
}

export function* watchCECContactById() {
  yield takeEvery(CEC_CONTACT_GET_BY_ID, getCECContactById);
}

export function* watchEditCECContact() {
  yield takeEvery(CEC_CONTACT_EDIT, editCECContact);
}

export function* watchDeleteCECContact() {
  yield takeEvery(CEC_CONTACT_DELETE, deleteCECContact);
}

export function* watchBulkDeleteCECContact() {
  yield takeEvery(CEC_CONTACT_BULK_DELETE, bulkDeleteCECContact);
}

export function* watchImportCECContactFromClient() {
  yield takeEvery(CEC_CONTACT_IMPORT_FROM_CLIENT, importCECContactFromClient);
}

export function* watchImportCECContactFromCSV() {
  yield takeEvery(CEC_CONTACT_IMPORT_FROM_CSV, importCECContactFromCSV);
}

export default function* rootSaga() {
  yield all([
    fork(watchCECContactList),
    fork(watchAddNewCECContact),
    fork(watchCECContactById),
    fork(watchEditCECContact),
    fork(watchDeleteCECContact),
    fork(watchBulkDeleteCECContact),
    fork(watchImportCECContactFromClient),
    fork(watchImportCECContactFromCSV),
  ]);
}

export { getCECContactListRequest, editCECContactRequest, getCECContactListByQueryRequest };
