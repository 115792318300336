import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, border } from 'styled-system';
import { Box } from './box';
import { Text } from './text';

const propTypes = {
  containerProps: PropTypes.shape({}),
  label: PropTypes.string,
};

const inputVariant = ({ theme, variant }) => {
  if (!variant)
    return `
    padding: ${theme.space[1]}
  `;

  if (variant === 'search') {
    return `
      height: 100%;
      font-size: ${theme.fontSizes[3]};
      padding-left: ${theme.space[5]};
      border-bottom: ${theme.borders[3]};
      border-radius: ${theme.radii.searchBar};
      background: ${theme.colors.greys[0]};
      width: 90%;
    `;
  }

  if (variant === 'login') {
    return `
      height: 100%;
      font-size: ${theme.fontSizes[3]};
      padding-left: ${theme.space[4]};
      border-bottom: ${theme.borders[3]};
      border-radius: ${theme.radii.rounded};
      background: ${theme.colors.greys[0]};
      width: 100%;
    `;
  }

  if (variant === 'register') {
    return `
      height: 100%;
      font-size: ${theme.fontSizes[3]};
      padding-left: ${theme.space[4]};
      border-bottom: ${theme.borders[3]};
      border-radius: ${theme.radii.rounded};
      background: ${theme.colors.greys[0]};
      width: 100%;
    `;
  }

  return '';
};

const StyledInput = styled.input`
  font: inherit;

  ${inputVariant}
  ${space}
  ${border}
`;

const defaultProps = {
  containerProps: {},
  label: '',
};

const Input = React.forwardRef(({ containerProps, label, ...inputProps }, ref) => (
  <Box flexDirection="column" {...containerProps}>
    {label && (
      <Text as="label" fontSize={2} mb={2}>
        {label}
      </Text>
    )}
    <StyledInput as="input" {...inputProps} ref={ref} />
  </Box>
));

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export { Input };
