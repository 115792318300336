import { Auth } from 'aws-amplify';
export async function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem('token');

  try {
    const newToken = (await Auth.currentSession()).getIdToken().getJwtToken();

    if (newToken) {
      token = newToken;
      localStorage.setItem('token', newToken);
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error refreshing token', error);
    console.log('cannot refresh, redirect to login');
    window.location = '/user/login';
    return null;
  }

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
}

export function handleResponse(response) {
  return response.text().then(text => {
    if (response.status >= 200 && response.status < 300) {
      const data = text && JSON.parse(text);
      return data;
    } else if (response.status === 400) {
      return Promise.reject(JSON.parse(text));
    } else if (response.status === 401) {
      // localStorage.removeItem('token');
      window.location = '/unauthorized';
    } else {
      //const error = response.statusText;
      return Promise.reject(text);
    }
  });
}

export const handleUnauthorized = response => {
  const { status } = response;

  if (status === 401) {
    // localStorage.removeItem('token');
    window.location = '/unauthorized';
  }
};
