import React from 'react';
import { defaultDirection } from '../constants/defaultValues';
import jwt from 'jsonwebtoken';
import { Redirect, Route } from 'react-router-dom';
import { permission } from '../constants/permission';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const isFormEligibleForPdfExport = fileName => {
  const eligiblePdfExportForms = ['ES3006E-Final', 'YJC3006E-Final'];
  const matchArray = eligiblePdfExportForms.filter(e => e === fileName);
  return matchArray && matchArray.length > 0;
};

export const formatErrorMessageFromObject = obj => {
  if (obj) {
    const err = obj?.message ?? obj;
    if (typeof err === 'string' || err instanceof String) {
      return err;
    }
  }
  return 'There was a problem detected. Please contact support.';
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ':' + now.getMinutes();
};

export const getDayDifference = (date1, date2) => {
  // To calculate the time difference of two dates
  var differenceInTime = date1.getTime() - date2.getTime();

  // To calculate the no. of days between two dates
  var differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.floor(differenceInDays);
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = localValue => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const buildUrl = (url, parameters) => {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
};

export const getProgramName = id => {
  switch (id) {
    case 1:
      return 'GO';
    case 2:
      return 'ES';
    case 3:
      return 'SE';
    case 4:
      return 'COJG';
    case 5:
      return 'ODSP';
    case 6:
      return 'YESS';
    case 7:
      return 'YJC';
    case 8:
      return 'YJC-S';
    case 9:
      return 'LBS';
    case 10:
      return 'ARI';
    case 11:
      return 'HUB';
    case 12:
      return 'SAO';
    case 13:
      return 'OW-EP';
    case 14:
      return 'IES';
    case 15:
      return 'UNASSISTED';
    default:
      return 'ERROR';
  }
};

export const getCommunicationType = id => {
  switch (id) {
    case 0:
      return 'Note';
    case 1:
      return 'Email';
    case 2:
      return 'Phone';
    case 3:
      return 'Text';
    case 4:
      return 'Virtual';
    default:
      return 'N/A';
  }
};

export const getProgramData = () => {
  var programData = [];
  programData.push({
    id: 15,
    program: 'unassisted',
    icon: 'simple-icon-plane',
    title: 'UNASSISTED Program',
    detail: 'Default program to start.',
  });
  programData.push({
    id: 1,
    program: 'go',
    icon: 'simple-icon-plane',
    title: 'GO Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 2,
    program: 'es',
    icon: 'iconsminds-male',
    title: 'ES Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 3,
    program: 'se',
    icon: 'iconsminds-male',
    title: 'SE Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 4,
    program: 'cojg',
    icon: 'iconsminds-male',
    title: 'COJG Program',
    detail:
      'Canada-Ontario Job Grant (COJG) provides opportunities for employers, individually or in groups, to invest in their workforce, with help from the government.',
  });

  programData.push({
    id: 5,
    program: 'odsp',
    icon: 'iconsminds-male',
    title: 'ODSP Program',
    detail:
      'If you don’t have enough money for food and housing, or if you have a disability and are in financial need, you can apply for financial and employment assistance through Ontario Works or the Ontario Disability Support Program (ODSP).',
  });

  programData.push({
    id: 6,
    program: 'yess',
    icon: 'iconsminds-male',
    title: 'YESS Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 7,
    program: 'yjc',
    icon: 'iconsminds-male',
    title: 'YJC Program',
    detail:
      'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
  });

  programData.push({
    id: 8,
    program: 'yjc-s',
    icon: 'iconsminds-male',
    title: 'YJC-S Program',
    detail:
      'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
  });

  programData.push({
    id: 9,
    program: 'lbs',
    icon: 'iconsminds-male',
    title: 'LBS Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 10,
    program: 'ari',
    icon: 'iconsminds-male',
    title: 'ARI Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 11,
    program: 'hub',
    icon: 'iconsminds-male',
    title: 'HUB Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 12,
    program: 'sao',
    icon: 'iconsminds-male',
    title: 'SAO Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 13,
    program: 'ow-ep',
    icon: 'iconsminds-male',
    title: 'OW-EP Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 14,
    program: 'ies',
    icon: 'iconsminds-male',
    title: 'IES Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  return {
    en: programData,
  };
};

export const programData = {
  en: [
    {
      id: 15,
      program: 'unassisted',
      icon: 'simple-icon-plane',
      title: 'UNASSISTED Program',
      detail: 'Default program to start.',
    },
    {
      id: 1,
      program: 'go',
      icon: 'simple-icon-plane',
      title: 'GO Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 2,
      program: 'es',
      icon: 'iconsminds-male',
      title: 'ES Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 3,
      program: 'se',
      icon: 'iconsminds-male',
      title: 'SE Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 4,
      program: 'cojg',
      icon: 'iconsminds-male',
      title: 'COJG Program',
      detail:
        'Canada-Ontario Job Grant (COJG) provides opportunities for employers, individually or in groups, to invest in their workforce, with help from the government.',
    },
    {
      id: 5,
      program: 'odsp',
      icon: 'iconsminds-male',
      title: 'ODSP Program',
      detail:
        'If you don’t have enough money for food and housing, or if you have a disability and are in financial need, you can apply for financial and employment assistance through Ontario Works or the Ontario Disability Support Program (ODSP).',
    },
    {
      id: 6,
      program: 'yess',
      icon: 'iconsminds-male',
      title: 'YESS Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 7,
      program: 'yjc',
      icon: 'iconsminds-male',
      title: 'YJC Program',
      detail:
        'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
    },
    {
      id: 8,
      program: 'yjc-s',
      icon: 'iconsminds-male',
      title: 'YJC-S Program',
      detail:
        'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
    },
    {
      id: 9,
      program: 'lbs',
      icon: 'iconsminds-male',
      title: 'LBS Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 10,
      program: 'ari',
      icon: 'iconsminds-male',
      title: 'ARI Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 11,
      program: 'hub',
      icon: 'iconsminds-male',
      title: 'HUB Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 12,
      program: 'sao',
      icon: 'iconsminds-male',
      title: 'SAO Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 13,
      program: 'ow-ep',
      icon: 'iconsminds-male',
      title: 'OW-EP Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 14,
      program: 'ies',
      icon: 'iconsminds-male',
      title: 'IES Program',
      detail: 'This is a program that will help you obtain a job.',
    },
  ],
};

export const getActionPlanTabId = programId => {
  switch (programId) {
    case 3:
      return 5;
    default:
      return 4;
  }
};

export const getCompanyClass = () => {
  if (document.location.host.includes('meta.')) {
    return 'meta';
  }
  if (document.location.host.includes('escases.azurewebsites.net')) {
    return 'meta';
  }
  return '';
};

export const getCompanyTitle = () => {
  if (getCompanyName()) {
    return `${getCompanyName().toUpperCase()} Case Management`;
  } else {
    return 'ESCASES Case Management';
  }
};

export const isCompanyAllowed = arrayOfAllowedCompanies => {
  var currentCompany = getCompanyName();
  const hasCompanyInArray = arrayOfAllowedCompanies.includes(currentCompany);
  return hasCompanyInArray;
};

export const getCompanyName = () => {
  if (document.location.host.includes('meta.')) {
    return 'meta';
  }

  if (document.location.host.includes('escases.azurewebsites.net')) {
    return 'meta';
  }

  if (document.location.host.includes('giag.')) {
    return 'giag';
  }

  if (document.location.host.includes('keys.')) {
    return 'keys';
  }

  if (document.location.host.includes('restart.')) {
    return 'restart';
  }

  if (document.location.host.includes('sandbox.')) {
    return 'sandbox';
  }

  if (document.location.host.includes('cdssab.')) {
    return 'cdssab';
  }

  if (document.location.host.includes('lacle.')) {
    return 'lacle';
  }

  if (document.location.host.includes('amdsb.')) {
    return 'amdsb';
  }

  if (document.location.host.includes('loyalist.')) {
    return 'loyalist';
  }
  if (document.location.host.includes('vccs.')) {
    return 'vccs';
  }
  if (document.location.host.includes('ysb.')) {
    return 'ysb';
  }
  if (document.location.host.includes('agilec.')) {
    return 'agilec';
  }
  if (document.location.host.includes('ymca-ceo.')) {
    return 'ymca-ceo';
  }
  if (document.location.host.includes('ymca-sm.')) {
    return 'ymca-sm';
  }
  if (document.location.host.includes('fleming-crew-hal.')) {
    return 'fleming-crew-hal';
  }
  if (document.location.host.includes('fleming-crew-ptbo.')) {
    return 'fleming-crew-ptbo';
  }
  if (document.location.host.includes('fleming-lbs-lindsay.')) {
    return 'fleming-lbs-lindsay';
  }
  if (document.location.host.includes('epcjobs.')) {
    return 'epcjobs';
  }
  if (document.location.host.includes('clrn.')) {
    return 'clrn';
  }
  if (document.location.host.includes('careeredge.')) {
    return 'careeredge';
  }
  return '';
};

export const decodeJwt = token => {
  if (!token) {
    console.log('Cannot decode empty token.');
    return null;
  }
  const decoded = jwt.decode(token);
  decoded.unique_name = decoded['custom:unique_name'];
  decoded.fullName = decoded['custom:fullName'];
  decoded.role = decoded['custom:role'];
  decoded.avatar = decoded['custom:avatar'];
  return decoded;
};

export const hasAccess = (permissions, permission) => {
  if (!permission) {
    return false;
  }
  if (!permissions) {
    console.log('unknown permissions, denying access', permission);
    return false;
  }
  const isGranted = permissions.includes(permission);
  if (!isGranted) {
    // console.log(`This user does not have ${permission} permission`);
  }

  return isGranted;
};

export const AuthRoute = ({ component: Component, requiredPermissions, permissions, ...rest }) => {
  let isAllowed = true;
  if (requiredPermissions && requiredPermissions.length > 0) {
    for (let permission of requiredPermissions) {
      if (!hasAccess(permissions, permission)) {
        isAllowed = false;
        break;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAllowed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { requiredPermissions: requiredPermissions },
            }}
          />
        )
      }
    />
  );
};

export const getProgramColor = item => {
  let color = 'primary';
  switch (item) {
    case 'GO':
      color = 'primary';
      break;

    case 'ES':
      color = 'success';
      break;

    case 'SE':
      color = 'danger';
      break;

    case 'COJG':
      color = 'warning';
      break;

    case 'ODSP':
      color = 'info';
      break;

    case 'YESS':
      color = 'dark';
      break;
    case 'Employer':
      color = 'info';
      break;

    default:
      color = 'primary';
      break;
  }

  return color;
};

export const getStatusColor = item => {
  let color = 'primary';
  switch (item) {
    case 1: //active
      color = 'success';
      break;

    case 2: //open
      color = 'warning';
      break;
    case 3: //closed
      color = 'dark';
      break;
    case 4: //IR
      color = 'success';
      break;
    case 5: //Active – On Hold
      color = 'success';
      break;
    case 6: //Active – Outcomes
      color = 'success';
      break;
    case 7: //Unassigned
      color = 'info';
      break;
    case 8: //Unassigned
      color = 'danger';
      break;
    case 15:
      color = 'light';
      break;
    default:
      color = 'success';
  }
  return color;
};

export const getAvatarFromContact = item => {
  if (item) {
    var avatar = `${item.firstName.charAt(0)}${item.lastName.charAt(0)}`;
    return avatar.toUpperCase();
  }
  return '';
};

export const isAdmin = role => {
  const roles = ['Administrator', 'System Administrator', 'LearningHUB Admin'];

  return roles.some(a => a === role);
};

export const timestampFileName = () => {
  var today = new Date();
  const fileName = `${today.getFullYear()}${
    today.getMonth() + 1
  }${today.getDate()}${today.getHours()}${today.getMinutes()}`;

  return fileName;
};

export const getCounsellorLabel = programId => {
  return programId === 11 ? 'Practitioner' : 'Case Manager'; // 11 is Hub
};

export const getJobDeveloperLabel = programId => {
  return programId === 11 ? 'Practitioner' : 'Employer Liaison'; // 11 is Hub
};

export const getMaxValue = values => {
  return Math.max.apply(Math, values);
};

export const getCurrentFiscalYear = () => {
  let currentDate = new Date();
  let month = currentDate.getMonth();
  let year = currentDate.getFullYear();
  return month < 3 ? `${year - 1}-${year}` : `${year}-${year + 1}`;
};

export const nocCodeOptions = [
  { key: 0, value: '0 Management occupations', label: '0 Management occupations' },
  {
    key: 1,
    value: '1 Business, finance and administration occupations',
    label: '1 Business, finance and administration occupations',
  },
  {
    key: 2,
    value: '2 Natural and applied sciences and related occupations',
    label: '2 Natural and applied sciences and related occupations',
  },
  { key: 3, value: '3 Health occupations', label: '3 Health occupations' },
  {
    key: 4,
    value: '4 Occupations in education, law and social, community and government services',
    label: '4 Occupations in education, law and social, community and government services',
  },
  {
    key: 5,
    value: '5 Occupations in art, culture, recreation and sport',
    label: '5 Occupations in art, culture, recreation and sport',
  },
  { key: 6, value: '6 Sales and service occupations', label: '6 Sales and service occupations' },
  {
    key: 7,
    value: '7 Trades, transport and equipment operators and related occupations',
    label: '7 Trades, transport and equipment operators and related occupations',
  },
  {
    key: 8,
    value: '8 Natural resources, agriculture and related production occupations',
    label: '8 Natural resources, agriculture and related production occupations',
  },
  {
    key: 9,
    value: '9 Occupations in manufacturing and utilities',
    label: '9 Occupations in manufacturing and utilities',
  },
];

export const jobTypeOptions = [
  { key: 1, value: 'Permanent (Full-time)', label: 'Permanent (Full-time)' },
  { key: 2, value: 'Permanent (Part-time)', label: 'Permanent (Part-time)' },
  { key: 3, value: 'Contract', label: 'Contract' },
  { key: 4, value: 'Seasonal', label: 'Seasonal' },
  { key: 5, value: 'Temporary (Full-time)', label: 'Temporary (Full-time)' },
  { key: 6, value: 'Temporary (Part-time)', label: 'Temporary (Part-time)' },
  { key: 7, value: 'Casual. ', label: 'Casual' },
];
