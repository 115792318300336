import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import { buildUrl } from '../../../helpers/Utils';
import axios from 'axios';
import Config from '../../../config';

import {
  BUDGET_GET_LIST,
  BUDGET_ADD_ITEM,
  BUDGET_EDIT_ITEM,
  BUDGET_DELETE_ITEM,
} from '../../actions';

import {
  getBudgetListSuccess,
  getBudgetListError,
  addBudgetItemSuccess,
  addBudgetItemError,
  editBudgetItemError,
} from './actions';

const getBudgetListRequest = async query => {
  let url = `${Config.apiServerHost}/api/Budget/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getBudgetsByProgramRequest = async program => {
  return await axios.get(
    `${Config.apiServerHost}/api/Budget/fiscalYear/${program}`,
    await authHeader()
  );
};

const getBudgetRequest = async (program, fiscal) => {
  return await axios.get(
    `${Config.apiServerHost}/api/Budget/${program}/${fiscal}`,
    await authHeader()
  );
};

function* getBudgetListItems({ payload }) {
  try {
    const response = yield call(getBudgetListRequest, payload);
    yield put(getBudgetListSuccess(response.data));
  } catch (error) {
    yield put(getBudgetListError(error));
  }
}

const poshBudgetListRequest = async budget => {
  return await axios.post(`${Config.apiServerHost}/api/Budget`, budget, await authHeader());
};

function* editBudgetItem({ payload }) {
  try {
    yield call(poshBudgetListRequest, payload);
    const res = yield call(getBudgetListRequest);
    yield put(getBudgetListSuccess(res.data));
  } catch (error) {
    yield put(editBudgetItemError(error));
  }
}

const deleteBudgetListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/Budget/${id}`, await authHeader());
};
function* deleteBudgetItem({ payload }) {
  try {
    yield call(deleteBudgetListRequest, payload);
    const res = yield call(getBudgetListRequest);
    yield put(getBudgetListSuccess(res.data));
  } catch (error) {
    yield put(editBudgetItemError(error));
  }
}

const addBudgetItemRequest = async budget => {
  return await axios.post(`${Config.apiServerHost}/api/Budget`, budget, await authHeader());
};

function* addBudgetItem({ payload }) {
  try {
    const response = yield call(addBudgetItemRequest, payload);
    yield put(addBudgetItemSuccess(response));
  } catch (error) {
    yield put(addBudgetItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(BUDGET_GET_LIST, getBudgetListItems);
}

export function* wathcAddItem() {
  yield takeEvery(BUDGET_ADD_ITEM, addBudgetItem);
}
export function* wathcEditItem() {
  yield takeEvery(BUDGET_EDIT_ITEM, editBudgetItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(BUDGET_DELETE_ITEM, deleteBudgetItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcEditItem), fork(wathcDeleteItem)]);
}

export { getBudgetRequest, getBudgetsByProgramRequest };
