import {
  CLIENT_SUPPORT_ADD_NEW,
  CLIENT_SUPPORT_ADD_NEW_ERROR,
  CLIENT_SUPPORT_DELETE,
  CLIENT_SUPPORT_DELETE_ERROR,
  CLIENT_SUPPORT_EDIT,
  CLIENT_SUPPORT_EDIT_ERROR,
  CLIENT_SUPPORT_GET_BY_ID,
  CLIENT_SUPPORT_GET_BY_ID_ERROR,
  CLIENT_SUPPORT_GET_BY_ID_SUCCESS,
  CLIENT_SUPPORT_GET_LIST,
  CLIENT_SUPPORT_GET_LIST_ERROR,
  CLIENT_SUPPORT_GET_LIST_SUCCESS,
  CLIENT_SUPPORT_ADD_NEW_REQUEST,
  CLIENT_SUPPORT_LOG_LIST,
  CLIENT_SUPPORT_LOG_LIST_ERROR,
  CLIENT_SUPPORT_DELETE_ATTACHMENT,
  CLIENT_SUPPORT_DELETE_ATTACHMENT_SUCCESS,
  CLIENT_SUPPORT_DELETE_ATTACHMENT_ERROR,
  CLIENT_SUPPORT_REPORT,
  CLIENT_SUPPORT_REPORT_SUCCESS,
  CLIENT_SUPPORT_REPORT_ERROR,
} from './types';
// clientSupport
const initialState = {
  clientSupports: [],
  clientSupport: null,
  clientSupportLogs: [],
  totalExpense: 0,
  itemCount: 0,
  totalContract: 0,
  pageCount: 0,
  totalPage: 0,
  totalApprove: 0,
  totalSubmit: 0,
  totalRejected: 0,
  totalClosed: 0,
  totalDraft: 0,
  totalCompleted: 0,
  totalAll: 0,
  totalSupportRequest: 0,
  totalSupportActual: 0,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_SUPPORT_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case CLIENT_SUPPORT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        clientSupport: payload,
      };

    case CLIENT_SUPPORT_LOG_LIST:
    case CLIENT_SUPPORT_GET_LIST:
    case CLIENT_SUPPORT_REPORT:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case CLIENT_SUPPORT_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        clientSupports: data,
        loading: false,
        itemCount,
        pageCount,
        totalSupportRequest: payload.totalSupportRequest,
        totalSupportActual: payload.totalSupportActual,
        addSuccess: true,
        editSuccess: true,
        deleteSuccess: true,
      };
    case CLIENT_SUPPORT_REPORT_SUCCESS:
      const {
        totalExpense,
        items,
        totalPage,
        totalContract,
        totalApprove,
        totalSubmit,
        totalRejected,
        totalClosed,
        totalDraft,
        totalCompleted,
        totalAll,
        totalSupportRequest,
        totalSupportActual,
      } = payload;
      return {
        ...state,
        totalExpense,
        itemCount: payload.itemCount,
        totalPage,
        totalContract,
        totalApprove,
        totalSubmit,
        totalRejected,
        totalClosed,
        totalDraft,
        totalCompleted,
        totalAll,
        totalSupportRequest,
        totalSupportActual,
        clientSupports: items,
        loading: false,
        deleteSuccess: true,
      };
    case CLIENT_SUPPORT_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case CLIENT_SUPPORT_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case CLIENT_SUPPORT_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };

    case CLIENT_SUPPORT_DELETE_ATTACHMENT:
    case CLIENT_SUPPORT_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };

    case CLIENT_SUPPORT_DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      };

    case CLIENT_SUPPORT_DELETE_ATTACHMENT_ERROR:
    case CLIENT_SUPPORT_DELETE_ERROR:
    case CLIENT_SUPPORT_ADD_NEW_ERROR:
    case CLIENT_SUPPORT_EDIT_ERROR:
    case CLIENT_SUPPORT_GET_BY_ID_ERROR:
    case CLIENT_SUPPORT_GET_LIST_ERROR:
    case CLIENT_SUPPORT_LOG_LIST_ERROR:
    case CLIENT_SUPPORT_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
