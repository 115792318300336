import axios from 'axios';
import { authHeader, handleResponse } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  APPLICANT_ADD_NEW,
  APPLICANT_EDIT,
  APPLICANT_GET_BY_QUERY,
  APPLICANT_GET_BY_ID,
  APPLICANT_DELETE,
  APPLICANT_COUNT_NEW,
  APPLICANT_REMOVE_ATTACHMENT,
} from './types';

import {
  addNewApplicantError,
  editApplicantError,
  getApplicantByQuerySuccess,
  getApplicantByQueryError,
  getApplicantByIdError,
  getApplicantByIdSuccess,
  deleteApplicantByIdErrorr,
  countNewApplicantSuccess,
  removeApplicantAttachmentSuccess,
} from './actions';

const sendEmailRequest = async requestObj => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(requestObj),
  };
  return await fetch(
    `${Config.apiServerHost}/api/JobApplicant/sendToEmployer`,
    requestOptions
  ).then(handleResponse);
};

const addNewApplicantReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/JobApplicant`, items, await authHeader());
};

const editApplicantRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/JobApplicant`, items, await authHeader());
};

const getApplicantByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/JobApplicant/${id}`, await authHeader());
};

const countNewApplicantRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/JobApplicant/countNew`, await authHeader());
};

const getApplicantByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/JobApplicant/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const removeApplicantAttachmentRequest = async query => {
  let url = `${Config.apiServerHost}/api/JobApplicant/removeAttachment`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const addApplicantAttachmentRequest = async (id, attachments) => {
  return axios.put(
    `${Config.apiServerHost}/api/JobApplicant/${id}/addAttachment`,
    attachments,
    await authHeader()
  );
};

const verifyApplicantRequest = async (id, query) => {
  let url = `${Config.apiServerHost}/api/JobApplicant/${id}/verify`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteApplicantRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Applicant/${id}`, await authHeader());
};
function* addNewApplicant({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewApplicantReqeust, data);
    response = yield call(getApplicantByQueryRequest, query);
    yield put(getApplicantByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewApplicantError(error.response.statusText));
  }
}

function* editApplicant({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editApplicantRequest, data);
    response = yield call(getApplicantByQueryRequest, query);
    yield put(getApplicantByQuerySuccess(response.data));
  } catch (error) {
    yield put(editApplicantError(error.response.statusText));
  }
}

function* getApplicantByQuery({ payload }) {
  try {
    const response = yield call(getApplicantByQueryRequest, payload);
    console.log(response.data);
    yield put(getApplicantByQuerySuccess(response.data));
  } catch (error) {
    yield put(getApplicantByQueryError(error.response.statusText));
  }
}

function* removeApplicantAttachment({ payload }) {
  try {
    let response = yield call(removeApplicantAttachmentRequest, payload);
    response = yield call(getApplicantByIdRequest, response.data);
    yield put(removeApplicantAttachmentSuccess(response.data));
  } catch (error) {
    yield put(getApplicantByQueryError(error.response.statusText));
  }
}

function* getApplicantById({ payload }) {
  try {
    const response = yield call(getApplicantByIdRequest, payload);
    yield put(getApplicantByIdSuccess(response.data));
  } catch (error) {
    yield put(getApplicantByIdError(error.response.statusText));
  }
}

function* deleteApplicant({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteApplicantRequest, data);
    response = yield call(getApplicantByQueryRequest, query);
    yield put(getApplicantByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteApplicantByIdErrorr(error.response.statusText));
  }
}

function* countNewApplicant({}) {
  try {
    const response = yield call(countNewApplicantRequest);
    yield put(countNewApplicantSuccess(response.data));
  } catch (error) {
    yield put(getApplicantByQueryError(error.response.statusText));
  }
}

export function* watchAddNewApplicant() {
  yield takeEvery(APPLICANT_ADD_NEW, addNewApplicant);
}
export function* watchEditApplicant() {
  yield takeEvery(APPLICANT_EDIT, editApplicant);
}

export function* watchGetApplicantByQuery() {
  yield takeEvery(APPLICANT_GET_BY_QUERY, getApplicantByQuery);
}

export function* watchGetApplicantById() {
  yield takeEvery(APPLICANT_GET_BY_ID, getApplicantById);
}

export function* watchDeleteApplicant() {
  yield takeEvery(APPLICANT_DELETE, deleteApplicant);
}

export function* watchCountingNewApplicant() {
  yield takeEvery(APPLICANT_COUNT_NEW, countNewApplicant);
}

export function* watchRemoveApplicantAttachment() {
  yield takeEvery(APPLICANT_REMOVE_ATTACHMENT, removeApplicantAttachment);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewApplicant),
    fork(watchEditApplicant),
    fork(watchGetApplicantByQuery),
    fork(watchDeleteApplicant),
    fork(watchGetApplicantById),
    fork(watchCountingNewApplicant),
    fork(watchRemoveApplicantAttachment),
  ]);
}

export {
  sendEmailRequest,
  getApplicantByQueryRequest,
  verifyApplicantRequest,
  removeApplicantAttachmentRequest,
  addApplicantAttachmentRequest,
};
