import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_JOB_MATCH_ADD_NEW,
  CLIENT_JOB_MATCH_EDIT,
  CLIENT_JOB_MATCH_GET_BY_QUERY,
  CLIENT_JOB_MATCH_GET_SINGLE_BY_QUERY,
  CLIENT_JOB_MATCH_GET_BY_ID,
  CLIENT_JOB_MATCH_DELETE,
} from './types';

import {
  addNewClientJobMatchError,
  editClientJobMatchError,
  getClientJobMatchByQuerySuccess,
  getClientJobMatchByQueryError,
  getClientJobMatchByIdError,
  getClientJobMatchByIdSuccess,
  deleteClientJobMatchByIdErrorr,
} from './actions';

const addNewClientJobMatchReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/ClientJobMatch`, items, await authHeader());
};

const editClientJobMatchRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/ClientJobMatch`, items, await authHeader());
};

const getClientJobMatchByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientJobMatch/${id}`, await authHeader());
};

const getClientJobMatchByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientJobMatch/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getSingleClientJobMatchByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientJobMatch/fetchSingle`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteClientJobMatchRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ClientJobMatch/${id}`, await authHeader());
};
function* addNewClientJobMatch({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewClientJobMatchReqeust, payload);
    response = yield call(getSingleClientJobMatchByQueryRequest, query);
    yield put(getClientJobMatchByIdSuccess(response.data));
  } catch (error) {
    yield put(addNewClientJobMatchError(error.response.statusText));
  }
}

function* editClientJobMatch({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editClientJobMatchRequest, payload);
    response = yield call(getSingleClientJobMatchByQueryRequest, query);
    yield put(getClientJobMatchByIdSuccess(response.data));
  } catch (error) {
    yield put(editClientJobMatchError(error.response.statusText));
  }
}

function* getSingleClientJobMatchByQuery({ payload }) {
  try {
    const response = yield call(getSingleClientJobMatchByQueryRequest, payload);
    yield put(getClientJobMatchByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientJobMatchByQueryError(error.response.statusText));
  }
}

function* getClientJobMatchByQuery({ payload }) {
  try {
    const response = yield call(getClientJobMatchByQueryRequest, payload);
    yield put(getClientJobMatchByQuerySuccess(response.data));
  } catch (error) {
    yield put(getClientJobMatchByQueryError(error.response.statusText));
  }
}

function* getClientJobMatchById({ payload }) {
  try {
    const response = yield call(getClientJobMatchByIdRequest, payload);
    yield put(getClientJobMatchByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientJobMatchByIdError(error.response.statusText));
  }
}

function* deleteClientJobMatch({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteClientJobMatchRequest, data);
    response = yield call(getClientJobMatchByQueryRequest, query);
    yield put(getClientJobMatchByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteClientJobMatchByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewClientJobMatch() {
  yield takeEvery(CLIENT_JOB_MATCH_ADD_NEW, addNewClientJobMatch);
}
export function* watchEditClientJobMatch() {
  yield takeEvery(CLIENT_JOB_MATCH_EDIT, editClientJobMatch);
}

export function* watchGetClientJobMatchByQuery() {
  yield takeEvery(CLIENT_JOB_MATCH_GET_BY_QUERY, getClientJobMatchByQuery);
}

export function* watchGetSingleClientJobMatchByQuery() {
  yield takeEvery(CLIENT_JOB_MATCH_GET_SINGLE_BY_QUERY, getSingleClientJobMatchByQuery);
}

export function* watchGetClientJobMatchById() {
  yield takeEvery(CLIENT_JOB_MATCH_GET_BY_ID, getClientJobMatchById);
}

export function* watchDeleteClientJobMatch() {
  yield takeEvery(CLIENT_JOB_MATCH_DELETE, deleteClientJobMatch);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewClientJobMatch),
    fork(watchEditClientJobMatch),
    fork(watchGetClientJobMatchByQuery),
    fork(watchDeleteClientJobMatch),
    fork(watchGetClientJobMatchById),
    fork(watchGetSingleClientJobMatchByQuery),
  ]);
}
