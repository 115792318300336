import {
  APPLICANT_ADD_NEW,
  APPLICANT_ADD_NEW_SUCCESS,
  APPLICANT_ADD_NEW_ERROR,
  APPLICANT_EDIT,
  APPLICANT_EDIT_SUCCESS,
  APPLICANT_EDIT_ERROR,
  APPLICANT_GET_BY_QUERY,
  APPLICANT_GET_BY_ID,
  APPLICANT_GET_BY_ID_SUCCESS,
  APPLICANT_GET_BY_ID_ERROR,
  APPLICANT_GET_BY_QUERY_SUCCESS,
  APPLICANT_GET_BY_QUERY_ERROR,
  APPLICANT_ADD_NEW_REQUEST,
  APPLICANT_DELETE,
  APPLICANT_DELETE_ERROR,
  APPLICANT_COUNT_NEW,
  APPLICANT_COUNT_NEW_SUCCESS,
  APPLICANT_REMOVE_ATTACHMENT,
  APPLICANT_REMOVE_ATTACHMENT_SUCCESS,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  totalNew: 0,
  totalAssisted: 0,
  totalUnassisted: 0,
  countNewItem: 0,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case APPLICANT_ADD_NEW:
    case APPLICANT_DELETE:
    case APPLICANT_GET_BY_ID:
    case APPLICANT_GET_BY_QUERY:
    case APPLICANT_COUNT_NEW:
    case APPLICANT_REMOVE_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: true,
        addSuccess: true,
        editSuccess: false,
        item: null,
      };
    case APPLICANT_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: true,
      };

    case APPLICANT_REMOVE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
        error: null,
      };
    case APPLICANT_EDIT_SUCCESS:
    case APPLICANT_ADD_NEW_REQUEST:
      return {
        ...state,
        loading: false,
        item: null,
        error: null,
      };
    case APPLICANT_COUNT_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        countNewItem: payload,
      };
    case APPLICANT_ADD_NEW_SUCCESS:
    case APPLICANT_GET_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, total, totalNew, totalAssisted, totalUnassisted, data } =
        payload;
      return {
        ...state,
        loading: false,
        items: data,
        total,
        itemCount,
        pageCount,
        totalNew,
        totalAssisted,
        totalUnassisted,
      };

    case APPLICANT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case APPLICANT_ADD_NEW_ERROR:
    case APPLICANT_DELETE_ERROR:
    case APPLICANT_EDIT_ERROR:
    case APPLICANT_GET_BY_ID_ERROR:
    case APPLICANT_GET_BY_QUERY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
