import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SITE_DELETE,
  SITE_EDIT,
  SITE_GET_BY_ID,
  SITE_ADD_NEW,
  SITE_GET_LIST,
  SITE_QUERY,
} from '../actions';
// Site
import {
  getSiteListSuccess,
  getSiteListError,
  editSiteSuccess,
  editSiteError,
  addNewSiteSuccess,
  addNewSiteError,
  deleteSiteSuccess,
  deleteSiteError,
  getSiteByIdSuccess,
  getSiteQuerySuccess,
  getSiteQueryError,
} from './actions';

const getSiteQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/Site`;
  return axios.get(url, await authHeader());
};

const getSiteListRequest = async seContractId => {
  return axios.get(
    `${Config.apiServerHost}/api/Site/se-contract-id/${seContractId}`,
    await authHeader()
  );
};

const addNewSiteReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/Site`, caseNote, await authHeader());
};

const editSiteRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/Site`, caseNote, await authHeader());
};

const deleteSiteRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Site/${id}`, await authHeader());
};

const getSiteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Site/${id}`, await authHeader());
};

function* getSiteList({ payload }) {
  try {
    const response = yield call(getSiteListRequest, payload);
    yield put(getSiteListSuccess(response.data));
  } catch (error) {
    yield put(getSiteListError(error.response.statusText));
  }
}

function* getSiteQuery({ payload }) {
  try {
    const response = yield call(getSiteQueryRequest, payload);
    yield put(getSiteQuerySuccess(response.data));
  } catch (error) {
    yield put(getSiteQueryError(error.response.statusText));
  }
}

function* addNewSite({ payload }) {
  try {
    let response = yield call(addNewSiteReqeust, payload);
    response = yield call(getSiteByIdRequest, response.data);
    yield put(addNewSiteSuccess(response.data));
  } catch (error) {
    yield put(addNewSiteError(error.response.statusText));
  }
}

function* editSite({ payload }) {
  try {
    let response = yield call(editSiteRequest, payload);
    response = yield call(getSiteByIdRequest, payload.id);
    yield put(editSiteSuccess(response.data));
  } catch (error) {
    yield put(editSiteError(error.response.statusText));
  }
}

function* getSiteById({ payload }) {
  try {
    const response = yield call(getSiteByIdRequest, payload);
    yield put(getSiteByIdSuccess(response.data));
  } catch (error) {
    yield put(editSiteError(error.response.statusText));
  }
}

function* deleteSite({ payload }) {
  try {
    const response = yield call(deleteSiteRequest, payload);
    yield put(deleteSiteSuccess(response.data));
  } catch (error) {
    yield put(deleteSiteError(error.response.statusText));
  }
}

export function* watchSiteQuery() {
  yield takeEvery(SITE_QUERY, getSiteQuery);
}

export function* watchSiteList() {
  yield takeEvery(SITE_GET_LIST, getSiteList);
}

export function* watchAddNewSite() {
  yield takeEvery(SITE_ADD_NEW, addNewSite);
}

export function* watchSiteById() {
  yield takeEvery(SITE_GET_BY_ID, getSiteById);
}

export function* watchEditSite() {
  yield takeEvery(SITE_EDIT, editSite);
}

export function* watchDeleteSite() {
  yield takeEvery(SITE_DELETE, deleteSite);
}

export default function* rootSaga() {
  yield all([
    fork(watchSiteQuery),
    fork(watchSiteList),
    fork(watchAddNewSite),
    fork(watchSiteById),
    fork(watchEditSite),
    fork(watchDeleteSite),
  ]);
}

export { getSiteQueryRequest };
