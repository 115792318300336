import {
  LOGIN_USER,
  RECONNECT_USER,
  RECONNECTING_USER,
  RECONNECTING_USER_DONE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_ERROR,
  USER_CLOSE_ERROR_MESSAGE,
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const reconnectUser = history => ({
  type: RECONNECT_USER,
  payload: { history },
});
export const reconnectingUser = history => ({
  type: RECONNECTING_USER,
  payload: {},
});
export const reconnectingUserDone = history => ({
  type: RECONNECTING_USER_DONE,
  payload: {},
});
export const loginUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = message => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (email, history) => ({
  type: FORGOT_PASSWORD,
  payload: { email, history },
});
export const forgotPasswordSuccess = forgotUserMail => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = message => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: message,
});

export const verifyCode = (code, history) => ({
  type: VERIFY_CODE,
  payload: { code, history },
});

export const verifyCodeSuccess = id => ({
  type: VERIFY_CODE_SUCCESS,
  payload: id,
});

export const verifyCodeError = error => ({
  type: VERIFY_CODE_ERROR,
  payload: error,
});

export const userResetPassword = (password, history) => ({
  type: RESET_PASSWORD,
  payload: { password, history },
});
export const userResetPasswordSuccess = data => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});
export const userResetPasswordError = error => ({
  type: RESET_PASSWORD_ERROR,
  payload: error,
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = message => ({
  type: REGISTER_USER_ERROR,
  payload: message,
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const closeUserErrorMessage = () => ({
  type: USER_CLOSE_ERROR_MESSAGE,
});
