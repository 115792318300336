import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  YJCS_CONTRACT_GET_LIST,
  YJCS_CONTRACT_ADD_ITEM,
  YJCS_CONTRACT_EDIT_ITEM,
  YJCS_CONTRACT_DELETE_ITEM,
  YJCS_CONTRACT_GET_BY_ID,
  YJCS_CONTRACT_GET_BY_EMPLOYER,
  YJCS_CONTRACT_GET_BY_CLIENT,
} from '../actions';

import {
  getYJCSContractListSuccess,
  getYJCSContractListError,
  addYJCSContractItemError,
  editYJCSContractItemError,
  deleteYJCSContractItemSuccess,
  deleteYJCSContractItemError,
  getYJCSContractByIdSuccess,
  getYJCSContractByIdError,
  getYJCSContractByEmployerError,
  getYJCSContractByEmployerSuccess,
  getYJCSContractByClientError,
  getYJCSContractByClientSuccess,
  addYJCSContractItemSuccess,
  editYJCSContractItemSuccess,
} from './actions';

const getYJCSContractByClientRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCSContract/getByClient`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getYJCSContractByEmployerRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCSContract/getByEmployer`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getYJCSContractsRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/YJCSContract`, await authHeader());
};

const getYJCSContractListRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCSContract/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getYJCSContractRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/YJCSContract/${id}`, await authHeader());
};

const addNewYJCSContractRequest = async data => {
  return await axios.post(`${Config.apiServerHost}/api/YJCSContract`, data, await authHeader());
};

const editYJCSContractRequest = async data => {
  return await axios.put(`${Config.apiServerHost}/api/YJCSContract`, data, await authHeader());
};

const deleteYJCSContractRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/YJCSContract/${id}`, await authHeader());
};

function* getYJCSContractByClient({ payload }) {
  try {
    const response = yield call(getYJCSContractByClientRequest, payload);
    yield put(getYJCSContractByClientSuccess(response.data));
  } catch (error) {
    yield put(getYJCSContractByClientError(error));
  }
}

function* getYJCSContractByEmployer({ payload }) {
  try {
    const response = yield call(getYJCSContractByEmployerRequest, payload);
    yield put(getYJCSContractByEmployerSuccess(response.data));
  } catch (error) {
    yield put(getYJCSContractByEmployerError(error));
  }
}

function* getYJCSContractById({ payload }) {
  try {
    const response = yield call(getYJCSContractRequest, payload);
    yield put(getYJCSContractByIdSuccess(response.data));
  } catch (error) {
    yield put(getYJCSContractByIdError(error));
  }
}

function* getYJCSContractList({ payload }) {
  try {
    const response = yield call(getYJCSContractListRequest, payload);
    yield put(getYJCSContractListSuccess(response.data));
  } catch (error) {
    yield put(getYJCSContractListError(error));
  }
}

function* addNewYJCSContract({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      yield call(addNewYJCSContractRequest, item);
      history.push('/app/contract/yjcs');
    } else {
      const { data, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        clientId: clientId ?? '',
        employerId: employerId ?? '',
        servicePlanHeaderId: servicePlanHeaderId ?? '',
      };

      yield call(addNewYJCSContractRequest, data);
      const response = yield call(getYJCSContractListRequest, query);
      yield put(addYJCSContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(addYJCSContractItemError(error));
  }
}

function* editYJCSContract({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      yield call(editYJCSContractRequest, item);
      history.push('/app/contract/yjcs');
    } else {
      const { data, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        clientId: clientId ?? '',
        employerId: employerId ?? '',
        servicePlanHeaderId: servicePlanHeaderId ?? '',
      };
      yield call(editYJCSContractRequest, data);
      const response = yield call(getYJCSContractListRequest, query);
      yield put(editYJCSContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(editYJCSContractItemError(error));
  }
}

function* deleteYJCSContract({ payload }) {
  try {
    const { clientId, id, employerId } = payload;
    const query = {
      clientId: clientId ?? '',
      employerId: employerId ?? '',
    };
    console.log('iiii=', id);
    yield call(deleteYJCSContractRequest, id);
    const response = yield call(getYJCSContractListRequest, query);
    yield put(deleteYJCSContractItemSuccess(response.data));
  } catch (error) {
    yield put(deleteYJCSContractItemError(error));
  }
}

export function* watchYJCSContractByClient() {
  yield takeEvery(YJCS_CONTRACT_GET_BY_CLIENT, getYJCSContractByClient);
}

export function* watchYJCSContractByEmployer() {
  yield takeEvery(YJCS_CONTRACT_GET_BY_EMPLOYER, getYJCSContractByEmployer);
}

export function* watchSEContactList() {
  yield takeEvery(YJCS_CONTRACT_GET_LIST, getYJCSContractList);
}

export function* watchSEContactGetById() {
  yield takeEvery(YJCS_CONTRACT_GET_BY_ID, getYJCSContractById);
}

export function* watchYJCSContractAddNew() {
  yield takeEvery(YJCS_CONTRACT_ADD_ITEM, addNewYJCSContract);
}
export function* watchYJCSContractEdit() {
  yield takeEvery(YJCS_CONTRACT_EDIT_ITEM, editYJCSContract);
}
export function* watchYJCSContractDelete() {
  yield takeEvery(YJCS_CONTRACT_DELETE_ITEM, deleteYJCSContract);
}

export default function* rootSaga() {
  yield all([
    fork(watchSEContactList),
    fork(watchSEContactGetById),
    fork(watchYJCSContractAddNew),
    fork(watchYJCSContractEdit),
    fork(watchYJCSContractDelete),
    fork(watchYJCSContractByEmployer),
    fork(watchYJCSContractByClient),
  ]);
}

export { getYJCSContractsRequest };
