import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EVENT_PARTICIPANT_DELETE,
  EVENT_PARTICIPANT_EDIT,
  EVENT_PARTICIPANT_GET_BY_ID,
  EVENT_PARTICIPANT_ADD_NEW,
  EVENT_PARTICIPANT_GET_LIST,
} from '../actions';
import Config from '../../config';

import {
  getEventParticipantListSuccess,
  getEventParticipantListError,
  editEventParticipantSuccess,
  editEventParticipantError,
  addNewEventParticipantSuccess,
  addNewEventParticipantError,
  deleteEventParticipantSuccess,
  deleteEventParticipantError,
  getEventParticipantByIdSuccess,
} from './actions';

const getEventParticipantListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/EventParticipant`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getEventParticipantListByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/EventParticipant/search`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewEventParticipantReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/EventParticipant`, actionPlan, await authHeader());
};

const editEventParticipantRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/EventParticipant`, actionPlan, await authHeader());
};

const deleteEventParticipantRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EventParticipant/${id}`, await authHeader());
};

const getEventParticipantByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EventParticipant/${id}`, await authHeader());
};

function* getEventParticipantList({ payload }) {
  try {
    const response = yield call(getEventParticipantListByClientIdRequest, payload);
    yield put(getEventParticipantListSuccess(response.data));
  } catch (error) {
    yield put(getEventParticipantListError(error.response.statusText));
  }
}

function* addNewEventParticipant({ payload }) {
  try {
    let response = yield call(addNewEventParticipantReqeust, payload);
    response = yield call(getEventParticipantByIdRequest, response.data);
    yield put(addNewEventParticipantSuccess(response.data));
  } catch (error) {
    yield put(addNewEventParticipantError(error.response.statusText));
  }
}

function* editEventParticipant({ payload }) {
  try {
    let response = yield call(editEventParticipantRequest, payload);
    response = yield call(getEventParticipantByIdRequest, response.data);
    yield put(editEventParticipantSuccess(response.data));
  } catch (error) {
    yield put(editEventParticipantError(error.response.statusText));
  }
}

function* getEventParticipantById({ payload }) {
  try {
    const response = yield call(getEventParticipantByIdRequest, payload);
    yield put(getEventParticipantByIdSuccess(response.data));
  } catch (error) {
    yield put(editEventParticipantError(error.response.statusText));
  }
}

function* deleteEventParticipant({ payload }) {
  try {
    const response = yield call(deleteEventParticipantRequest, payload);
    yield put(deleteEventParticipantSuccess(response.data));
  } catch (error) {
    yield put(deleteEventParticipantError(error.response.statusText));
  }
}

export function* watchEventParticipantList() {
  yield takeEvery(EVENT_PARTICIPANT_GET_LIST, getEventParticipantList);
}

export function* watchAddNewEventParticipant() {
  yield takeEvery(EVENT_PARTICIPANT_ADD_NEW, addNewEventParticipant);
}

export function* watchEventParticipantById() {
  yield takeEvery(EVENT_PARTICIPANT_GET_BY_ID, getEventParticipantById);
}

export function* watchEditEventParticipant() {
  yield takeEvery(EVENT_PARTICIPANT_EDIT, editEventParticipant);
}

export function* watchDeleteEventParticipant() {
  yield takeEvery(EVENT_PARTICIPANT_DELETE, deleteEventParticipant);
}

export default function* rootSaga() {
  yield all([
    fork(watchEventParticipantList),
    fork(watchAddNewEventParticipant),
    fork(watchEventParticipantById),
    fork(watchEditEventParticipant),
    fork(watchDeleteEventParticipant),
  ]);
}

export {
  getEventParticipantListByClientIdRequest,
  getEventParticipantListRequest,
  editEventParticipantRequest,
};
