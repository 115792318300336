export const POSTING_GET_LIST = 'POSTING_GET_LIST';
export const POSTING_GET_LIST_SUCCESS = 'POSTING_GET_LIST_SUCCESS';
export const POSTING_GET_BY_ID = 'POSTING_GET_BY_ID';
export const POSTING_GET_BY_ID_SUCCESS = 'POSTING_GET_BY_ID_SUCCESS';

export const POSTING_ADD_NEW = 'POSTING_ADD_NEW';
export const POSTING_ADD_NEW_REQUEST = 'POSTING_ADD_NEW_REQUEST';

export const POSTING_STATUS_CHANGE = 'POSTING_STATUS_CHANGE';
export const FILL_POSTING_POSITION = 'FILL_POSTING_POSITION';
export const POSTING_STATUS_CHANGE_SUCCESS = 'POSTING_STATUS_CHANGE_SUCCESS';
export const POSTING_RESULT_CHANGE = 'POSTING_RESULT_CHANGE';
export const POSTING_EDIT = 'POSTING_EDIT';
export const POSTING_DELETE = 'POSTING_DELETE';
export const POSTING_ERROR = 'POSTING_ERROR';

export const REMOVE_POSTING_CLIENT = 'REMOVE_POSTING_CLIENT';
export const REMOVE_POSTING_CLIENT_SUCCESS = 'REMOVE_POSTING_CLIENT_SUCCESS';
export const REMOVE_POSTING_CLIENT_ERROR = 'REMOVE_POSTING_CLIENT_ERROR';
