/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Box, Text } from './elements';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
  fallback: PropTypes.node,
  location: PropTypes.string.isRequired,
};

const defaultProps = {
  fallback: null,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
    console.error({ error, info });

    this.setState({
      error,
      info,
    });

    //TODO: WE SHOULD LOG THIS SOMEWHERE ON THE SERVICE
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  getWrapper = () => {
    return React.Fragment;
  };

  render() {
    const { fallback } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      const Wrapper = this.getWrapper();

      if (fallback) {
        const Component = fallback;
        return <Component />;
      }

      return (
        <Wrapper>
          <Box
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
            margin="auto"
          >
            <Box
              flexDirection="column"
              alignItems="center"
              color="red"
              fontWeight="500"
              margin="auto"
              fontSize="x-large"
            >
              <FontAwesomeIcon size="lg" color="red" icon={faExclamationTriangle} />
              <Text textAlign="center">Oh snap! Something went wrong.</Text>
              <Text textAlign="center">Please try reloading</Text>
            </Box>
          </Box>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;

export default ErrorBoundary;
